import React, { Component } from "react"
import cloneDeep from "lodash.clonedeep"
import ReactEcharts from "echarts-for-react"

class GuageN8 extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getOption = () => {
    return {
      tooltip: {
        formatter: "{a}{b}: {c}%",
      },
      series: [
        {
          name: "Cursos",
          type: "gauge",
          detail: { formatter: "{value}%" },
          axisLine: {
            lineStyle: {
              color: [
                [0.3, "#ec4561"],
                [0.7, "#f1b44c"],
                [1, "#02a499"],
              ],
              width: 5,
            },
          },
          axisLabel: {
            show: false,
          },
          data: [{ value: 34, name: "" }],
        },
      ],
    }
  }

  timeTicket = null
  getInitialState = () => ({ option: this.getOption() })

  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket)
    }
    this.timeTicket = setInterval(() => {
      const option = cloneDeep(this.state.option)
      option.series[0].data.value = (Math.random() * 100).toFixed(2) - 0
      this.setState({ option: option })
    }, 2000)
  }

  componentWillUnmount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket)
    }
  }

  render() {
    return (
      <React.Fragment>
        <ReactEcharts
          style={{ height: "160px", width: "100%" }}
          option={{
            tooltip: {
              formatter: "{a}{b}: {c}%",
            },
            legend: {
              show: false,
              fontSize: 12,
            },
            series: [
              {
                name: "Cursos completados",
                type: "gauge",
                detail: {
                  formatter: "{value}%",
                  fontSize: 18,
                  show: true,
                },
                axisLine: {
                  lineStyle: {
                    color: [
                      [0.3, "#ec4561"],
                      [0.7, "#f1b44c"],
                      [1, "#02a499"],
                    ],
                    width: 8,
                  },
                },
                axisLabel: {
                  show: false,
                  padding: 0,
                },
                data: [{ value: 34 }],
              },
            ],
          }}
        />
        <p style={{ position: "relative", top: -30 }} className="text-center">
          Completados
        </p>
      </React.Fragment>
    )
  }
}
export default GuageN8
