import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Row, Col, Button, Label, Progress } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  getCourse,
  getKnowledgeCategories,
  getSkills,
  getLevels,
  getCourseKnowledges,
  getUsers,
  getCourses,
  addCourse,
  addCourseClean,
  editCourse,
  editCourseClean,
} from "../../store/actions"
import moment from "moment"
import ImageUploadModal from "components/Common/ImageUploadModal"

const CoursesForm = props => {
  const [formError, setFormError] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [showImageUpload, setShowImageUpload] = useState(false)
  const [image, setImage] = useState(false)
  const [categoriesSelectedMulti, setCategoriesSelectedMulti] = useState([])
  const [previousCoursesSelectedMulti, setPreviousCoursesSelectedMulti] =
    useState([])
  const [responsiblesSelectedMulti, setResponsiblesSelectedMulti] = useState([])
  const [habilitiesSelectedMulti, setHabilitiesSelectedMulti] = useState([])
  const [knowledgesSelectedMulti, setKnowledgesSelectedMulti] = useState([])
  const [suggestedCoursesSelectedMulti, setSuggestedCoursesSelectedMulti] =
    useState([])

  const insert = props.insert ?? true

  const {
    courses,
    loadingCourses,
    errorCourses,
    knowledgeCategories,
    skills,
    levels,
    users,
    courseKnowledges,
    loading,
    insertedCourseError,
    insertedCourseDone,
    insertedCourse,
    updatedCourseError,
    updatedCourseDone,
    updatedCourse,
  } = useSelector(state => ({
    courses: state.CoursesList.courses,
    loadingCourses: state.CoursesList.loading,
    errorCourses: state.CoursesList.error,
    knowledgeCategories: state.KnowledgeCategoriesList.knowledgeCategories,
    skills: state.SkillsList.skills,
    levels: state.LevelsList.levels,
    courseKnowledges: state.CourseKnowledgesList.courseKnowledges,
    users: state.UsersList.users,
    loading: state.CourseAdd.loading,
    insertedCourseError: state.CourseAdd.error,
    insertedCourseDone: state.CourseAdd.done,
    insertedCourse: state.CourseAdd.course,
    updateLoading: state.CourseAdd.loading,
    updatedCourseError: state.CourseEdit.error,
    updatedCourseDone: state.CourseEdit.done,
    updatedCourse: state.CourseEdit.course,
  }))

  const dispatch = useDispatch()

  const knowCat = props.course.knowledgeCategories
  const prevCourses = props.course.PreviousCourses
  const sugCourses = props.course.SuggestedCourses
  const respCourses = props.course.ResponsiblesCourses
  const know = props.course.knowledges
  const skl = props.course.abilities
  const vc = props.course.valoracionCurso
  const img = props.course.coverImage ?? ""

  useEffect(() => {
    let prevCoursesArray = []
    let sugCoursesArray = []
    let knowCatArray = []
    let knowArray = []
    let sklArray = []
    let rArray = []
    if (respCourses !== undefined)
      respCourses.forEach(respCourse => {
        const user = users.find(user => user.idUsuario === respCourse.idUser)
        if (user !== undefined)
          rArray.push({
            label: `${user.nombre} ${user.apellido}`,
            value: user.idUsuario,
          })
      })

    if (prevCourses !== undefined)
      prevCourses.forEach(prevCourse => {
        prevCoursesArray.push({
          label: `${prevCourse.courseName}`,
          value: prevCourse.idPreviousCourse,
        })
      })

    if (sugCourses !== undefined)
      sugCourses.forEach(sugCourse => {
        sugCoursesArray.push({
          label: `${sugCourse.courseName}`,
          value: sugCourse.idSuggedtedCourse,
        })
      })

    if (knowCat !== undefined)
      knowCat.forEach(knowC => {
        knowCatArray.push({
          label: `${knowC.knowledgeCategory}`,
          value: knowC.idKnowledgeCategory,
        })
      })

    if (know !== undefined)
      know.forEach(knowledge => {
        knowArray.push({
          label: `${knowledge.knowledge}`,
          value: knowledge.idKnowledge,
        })
      })

    if (skl !== undefined)
      skl.forEach(skill => {
        sklArray.push({
          label: `${skill.ability}`,
          value: skill.idAbility,
        })
      })

    setPreviousCoursesSelectedMulti(prevCoursesArray)
    setSuggestedCoursesSelectedMulti(sugCoursesArray)
    setCategoriesSelectedMulti(knowCatArray)
    setKnowledgesSelectedMulti(knowArray)
    setHabilitiesSelectedMulti(sklArray)
    setResponsiblesSelectedMulti(rArray)
    setImage(img)
  }, [prevCourses, sugCourses, knowCat, vc, users])

  useEffect(() => {
    dispatch(getCourses())
    dispatch(addCourseClean())
    dispatch(getKnowledgeCategories())
    dispatch(getSkills())
    dispatch(getLevels())
    dispatch(getCourseKnowledges())
    dispatch(getUsers())
    if (!insert) setReadOnly(true)
  }, [])

  const handleSubmit = (event, errors, values) => {
    if (readOnly) return
    if (showImageUpload) return
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    const abilities = habilitiesSelectedMulti.map(ability => ability.value)
    const courseKnowledges = knowledgesSelectedMulti.map(
      knowledge => knowledge.value
    )
    const knowledgeCategories = categoriesSelectedMulti.map(
      category => category.value
    )
    const previousCourses = previousCoursesSelectedMulti.map(
      previousCourse => previousCourse.value
    )
    const suggestedCourses = suggestedCoursesSelectedMulti.map(
      suggestedCourse => suggestedCourse.value
    )
    const responsibles =
      responsiblesSelectedMulti !== null
        ? responsiblesSelectedMulti.map(responsible => responsible.value)
        : []

    const request = {
      course: {
        claveCurso: values.claveCurso,
        nombreCurso: values.name,
        nivelCurso: values.level,
        tiempoDedicacion: values.dedication,
        minimoAprovatorio: parseInt(values.minApproval),
        valoracionCurso: values.valoration === "true",
      },
      coverImage: image,
      abilities: abilities,
      knowledge: courseKnowledges,
      knowledgeCategories: knowledgeCategories,
      previousCourses,
      suggestedCourses,
      responsibles,
    }

    if (insert) {
      dispatch(addCourse(request))
    } else {
      dispatch(
        editCourse({
          ...request,
          course: {
            ...request.course,
            idCurso: props.course.idCurso,
          },
        })
      )
    }
  }

  const handleCategoriesSelectedMulti = selectedMulti => {
    setCategoriesSelectedMulti(selectedMulti)
  }

  const handlePreviousCoursesSelectedMulti = selectedMulti => {
    setPreviousCoursesSelectedMulti(selectedMulti)
  }

  const handleResponsiblesSelectedMulti = selectedMulti => {
    setResponsiblesSelectedMulti(selectedMulti)
  }

  const handleHabilitiesSelectedMulti = selectedMulti => {
    setHabilitiesSelectedMulti(selectedMulti)
  }

  const handleKnowledgesSelectedMulti = selectedMulti => {
    setKnowledgesSelectedMulti(selectedMulti)
  }

  const handleSuggestedCoursesSelectedMulti = selectedMulti => {
    setSuggestedCoursesSelectedMulti(selectedMulti)
  }

  const sugerirClave = () => {}

  const categoriesOG =
    knowledgeCategories !== undefined
      ? knowledgeCategories.map(knowledgeCategory => {
          return {
            label: `${knowledgeCategory.knowledgeCategory}`,
            value: knowledgeCategory.idKnowledgeCategory,
          }
        })
      : []

  const skillsOG =
    skills !== undefined
      ? skills.map(skill => {
          return {
            label: skill.ability,
            value: skill.idAbility,
          }
        })
      : []

  const responsiblesOG =
    users !== undefined
      ? users.map(user => {
          return {
            label: `${user.nombre} ${user.apellido}`,
            value: user.idUsuario,
          }
        })
      : []

  const levelsOG =
    levels !== undefined
      ? levels.map(level => {
          return {
            label: level.level,
            value: level.idLevel,
          }
        })
      : []

  const courseKnowledgesOG =
    courseKnowledges !== undefined
      ? courseKnowledges.map(courseKnowledge => {
          return {
            label: `${courseKnowledge.knowledge}`,
            value: courseKnowledge.idKnowledge,
          }
        })
      : []

  const otherCourses = insert
    ? courses
    : courses.filter(
        foundCourse => props.course.idCurso !== foundCourse.idCurso
      )

  const previousCoursesOG =
    otherCourses !== undefined
      ? otherCourses.map(course => {
          return {
            label: `${course.nombreCurso}`,
            value: course.idCurso,
          }
        })
      : []

  const suggestedCoursesOG =
    otherCourses !== undefined
      ? otherCourses.map(course => {
          return {
            label: `${course.nombreCurso}`,
            value: course.idCurso,
          }
        })
      : []

  const categoriesOtionGroup = [
    {
      label: "Categorías de conocimiento",
      options: categoriesOG,
    },
  ]

  const previousCoursesOtionGroup = [
    {
      label: "Curso previo seriado por",
      options: previousCoursesOG,
    },
  ]

  const responsiblesOtionGroup = [
    {
      label: "Responsable",
      options: responsiblesOG,
    },
  ]

  const levelsOtionGroup = [
    {
      label: "Nivel",
      options: levelsOG,
    },
  ]

  const habilitiesOtionGroup = [
    {
      label: "Habilidad",
      options: skillsOG,
    },
  ]

  const knowledgesOtionGroup = [
    {
      label: "Conocimientos obtenidos",
      options: courseKnowledgesOG,
    },
  ]

  const suggestedCoursesOtionGroup = [
    {
      label: "Cursos sugeridos",
      options: suggestedCoursesOG,
    },
  ]

  return (
    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
      {insertedCourseError !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al registrar el curso: {insertedCourseError}
        </div>
      )}
      {updatedCourseError !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al editar el curso
        </div>
      )}
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="name">Imagen de portada</Label>
        </Col>
        <Col md={3}>
          {image !== "" && (
            <a
              className="btn btn-light"
              href={image}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-image"></i> Ver imagen
            </a>
          )}
          {image !== "" && <span className="pl-2 mr-2">&nbsp;</span>}
          {image === "" && readOnly && <span>No se ha cargado imagen</span>}
          {!readOnly && (
            <button
              type="button"
              onClick={() => setShowImageUpload(true)}
              className="btn btn-light"
            >
              {image !== "" ? (
                <i className="fa fa-sync"></i>
              ) : (
                <i className="fa fa-plus"></i>
              )}{" "}
              {image !== "" ? "Cambiar" : "Agregar"} imagen
            </button>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="name">* Nombre del curso:</Label>
        </Col>
        <Col md={9}>
          {readOnly ? (
            props.course.nombreCurso
          ) : (
            <AvField
              name="name"
              value={props.course.nombreCurso}
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="courses">* Categoría conocimiento:</Label>
        </Col>
        <Col md={9}>
          {readOnly ? (
            <span>
              {categoriesSelectedMulti
                .map(category => category.label.trim())
                .join(", ")}
            </span>
          ) : (
            <Select
              name="courses"
              value={categoriesSelectedMulti}
              isMulti={true}
              onChange={event => {
                handleCategoriesSelectedMulti(event)
              }}
              options={categoriesOtionGroup}
              classNamePrefix="select2-selection"
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="name">* ID Curso Henkel:</Label>
        </Col>
        <Col md={3}>
          {readOnly ? (
            props.course.claveCurso
          ) : (
            <AvField
              name="claveCurso"
              value={props.course.claveCurso}
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            />
          )}
        </Col>
        <Col md={3}>
          {/*<button onClick={sugerirClave} className="btn btn-link">
            Sugerida
          </button>*/}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="previousCourses">Curso previo seriado por:</Label>
        </Col>
        <Col md={9}>
          {readOnly ? (
            <span>
              {previousCoursesSelectedMulti
                .map(category => category.label.trim())
                .join(", ")}
            </span>
          ) : (
            <Select
              name="previousCourses"
              value={previousCoursesSelectedMulti}
              isMulti={true}
              onChange={event => {
                handlePreviousCoursesSelectedMulti(event)
              }}
              options={previousCoursesOtionGroup}
              classNamePrefix="select2-selection"
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="responsible">* Responsables:</Label>
        </Col>
        <Col md={9}>
          {readOnly ? (
            <span>
              {responsiblesSelectedMulti
                .map(category => category.label.trim())
                .join(", ")}
            </span>
          ) : (
            <Select
              name="responsible"
              value={responsiblesSelectedMulti}
              isMulti={true}
              onChange={event => {
                handleResponsiblesSelectedMulti(event)
              }}
              options={responsiblesOtionGroup}
              classNamePrefix="select2-selection"
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="level">* Nivel:</Label>
        </Col>
        <Col md={9}>
          {readOnly ? (
            <span>
              {
                levels.find(level => level.idLevel === props.course.nivelCurso)
                  ?.level
              }
            </span>
          ) : (
            <AvField
              name="level"
              value={props.course.nivelCurso}
              className="form-control"
              placeholder=""
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option>-- Seleccione nivel del curso --</option>
              {levels.map((element, _) => (
                <option value={element.idLevel} key={element.idLevel}>
                  {element.level}
                </option>
              ))}
            </AvField>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="dedication">* Dedicación:</Label>
        </Col>
        <Col md={readOnly ? 9 : 1}>
          {readOnly ? (
            `${props.course.tiempoDedicacion} minutos`
          ) : (
            <AvField
              name="dedication"
              value={props.course.tiempoDedicacion}
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
                number: {
                  value: true,
                  errorMessage: "Este campo debe ser un número",
                },
              }}
            />
          )}
        </Col>
        {!readOnly && (
          <Col md={3}>
            <p className="form-control-plaintext">Minutos</p>
          </Col>
        )}
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="habilities">* Habilidades otorgadas:</Label>
        </Col>
        <Col md={9}>
          {readOnly ? (
            <span>
              {habilitiesSelectedMulti
                .map(category => category.label.trim())
                .join(", ")}
            </span>
          ) : (
            <Select
              name="habilities"
              value={habilitiesSelectedMulti}
              isMulti={true}
              onChange={event => {
                handleHabilitiesSelectedMulti(event)
              }}
              options={habilitiesOtionGroup}
              classNamePrefix="select2-selection"
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="knowledges">* Conocimientos obtenidos:</Label>
        </Col>
        <Col md={9}>
          {readOnly ? (
            <span>
              {knowledgesSelectedMulti
                .map(category => category.label.trim())
                .join(", ")}
            </span>
          ) : (
            <Select
              name="knowledges"
              value={knowledgesSelectedMulti}
              isMulti={true}
              onChange={event => {
                handleKnowledgesSelectedMulti(event)
              }}
              options={knowledgesOtionGroup}
              classNamePrefix="select2-selection"
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="minApproval">* Mínimo aprobatorio:</Label>
        </Col>
        <Col md={2}>
          {readOnly ? (
            `${props.course.minimoAprovatorio} %`
          ) : (
            <AvField
              name="minApproval"
              value={props.course.minimoAprovatorio}
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
                number: {
                  value: true,
                  errorMessage: "Este campo debe ser un número",
                },
                min: {
                  value: 1,
                  errorMessage: "El mínimo aprobatorio no puede ser menor a 1",
                },
                max: {
                  value: 100,
                  errorMessage:
                    "El mínimo aprobatorio no puede ser mayor a 100",
                },
              }}
            />
          )}
        </Col>
        {!readOnly && (
          <Col md={3}>
            <p className="form-control-plaintext">%</p>
          </Col>
        )}
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="valoration">* Agregar valoración de curso:</Label>
        </Col>
        <Col md={9}>
          {readOnly ? (
            `${props.course.valoracionCurso ? "Sí" : "No"}`
          ) : (
            <AvField
              name="valoration"
              value={props.course.valoracionCurso === true ? "true" : "false"}
              className="form-control select2-selection"
              placeholder=""
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option value={"true"}>Sí</option>
              <option value={"false"}>No</option>
            </AvField>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="valoration">* Fecha próxima revisión:</Label>
        </Col>
        <Col md={3}>
          {insert && (
            <Flatpickr
              value={
                insert
                  ? moment(Date.now()).add(3, "years").format("DD/MM/YYYY")
                  : ""
              }
              className="form-control d-block"
              placeholder="dd/mm/yyyy"
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "d/m/Y",
              }}
            />
          )}
          {!insert && (
            <p className="form-control-plaintext">
              {moment(Date.now()).add(3, "years").format("DD/MM/YYYY")}
            </p>
          )}
        </Col>
        <Col md={3}>
          <p className="form-control-plaintext">(3 años por default)</p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} className="d-flex justify-content-end align-items-center">
          <Label for="suggestedCourses">Cursos sugeridos:</Label>
        </Col>
        <Col md={9}>
          {readOnly ? (
            <span>
              {suggestedCoursesSelectedMulti
                .map(category => category.label.trim())
                .join(", ")}
            </span>
          ) : (
            <Select
              name="suggestedCourses"
              value={suggestedCoursesSelectedMulti}
              isMulti={true}
              onChange={event => {
                handleSuggestedCoursesSelectedMulti(event)
              }}
              options={suggestedCoursesOtionGroup}
              classNamePrefix="select2-selection"
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          {readOnly ? (
            <button
              onClick={() => setReadOnly(false)}
              className="btn btn-secondary float-right btn-block"
              type="button"
            >
              <i className="fa fa-edit"></i> Editar
            </button>
          ) : (
            <Button className={`float-right  btn-block`} type="submit">
              {insert ? (
                <i className="fa fa-plus"></i>
              ) : (
                <i className="fa fa-sync"></i>
              )}
              {insert && (
                <span>{!loading ? " Crear curso" : " Creando curso..."}</span>
              )}
              {!insert && (
                <span>{!loading ? " Guardar" : " Guardando..."}</span>
              )}
            </Button>
          )}
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <Link to="/courses" className="btn btn-light btn-block">
            <i className="fa fa-undo"></i>{" "}
            {insert ? "Cancelar" : readOnly ? "Salir" : "Salir sin guardar"}
          </Link>
        </Col>
      </Row>
      {showImageUpload && (
        <ImageUploadModal
          onConfirm={image => {
            setImage(image)
            setShowImageUpload(false)
          }}
          onCancel={() => setShowImageUpload(false)}
          show={true}
        />
      )}
      {insertedCourseDone && insert && (
        <SweetAlert
          title="Curso registrado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() =>
            props.history.push(`/courses/detail/${insertedCourse.idCurso}`)
          }
        >
          <p>El curso fue registrado de forma exitosa.</p>
        </SweetAlert>
      )}
      {updatedCourseDone && !insert && (
        <SweetAlert
          title="Configuración actualizada exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(getCourse(props.course.idCurso))
            dispatch(editCourseClean())
            setReadOnly(true)
          }}
        >
          <p>La configuración del curso fue actualizada de forma exitosa.</p>
        </SweetAlert>
      )}
      <style jsx="true">{`
        Label {
          font-weight: bold;
        }
      `}</style>
    </AvForm>
  )
}

CoursesForm.propTypes = {
  course: PropTypes.object,
  insert: PropTypes.bool,
  history: PropTypes.object,
}

export default withRouter(CoursesForm)
