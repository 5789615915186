import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"
import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
  AvFeedback,
  AvInput,
  AvGroup,
} from "availity-reactstrap-validation"
import {
  getLessons,
  addQuizzAnswer,
  addQuizzAnswerClean,
  editQuizzAnswer,
  editQuizzAnswerClean,
} from "store/actions"

const QuizzFormModal = props => {
  const answers = props.quizz?.answers ?? []
  const dispatch = useDispatch()
  const [formError, setFormError] = useState(false)
  const [value, setValue] = useState(null)

  const { quizzAnswerAddDone, quizzAnswerEditDone } = useSelector(store => ({
    quizzAnswerAddDone: store.QuizzAnswerAdd.done,
    quizzAnswerEditDone: store.QuizzAnswerEdit.done,
  }))

  useEffect(() => {
    dispatch(getLessons(props.course.idCurso))
    return () => {
      setValue(null)
      setFormError(false)
    }
  }, [])

  const changeValue = event => {
    setValue(parseInt(event.target.value))
  }

  const handleAddAnswer = () => {
    const request = {
      idQuiz: props.quizz.idQuiz,
      valoration: 0,
    }
    dispatch(addQuizzAnswer(request))
  }

  const handleUpdateAnswerName = (idQuiz, answer) => {
    const request = {
      idQuiz: props.quizz.idQuiz,
      valoration: 1,
    }
    dispatch(editQuizzAnswer(request))
  }

  const handleSubmit = async (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
  }

  if (quizzAnswerAddDone) {
    dispatch(addQuizzAnswerClean())
    props.reload()
  }

  return (
    <Modal
      isOpen={props.quizz !== undefined}
      backdrop={"static"}
      id="staticBackdrop"
    >
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Quizz
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.onClose()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {formError && (
            <div className="alert alert-danger">
              Existen errores en el formulario. Por favor corrígelos para
              continuar.
            </div>
          )}
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="question"
                label="Ingresa la pregunta (únicamente opción múltiple)"
                className="form-control"
                placeholder="Ingresar"
                value={""}
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <button
                type="button"
                onClick={handleAddAnswer}
                className="btn btn-link"
              >
                <i className="fa fa-plus"></i> Agregar respuesta
              </button>
            </Col>
          </Row>
          <Row>
            <Col md={answers.length < 1 ? 12 : 1}>
              <AvRadioGroup
                name="private"
                className="form-control noNorder"
                onChange={changeValue}
                value={value}
                validate={{
                  required: {
                    value: true,
                    errorMessage:
                      "Agregue al menos una respuesta, marque la que sea correcta.",
                  },
                }}
              >
                {answers.map(answer => (
                  <div key={`answer-${answer.idQuizAnswer}`} className="mb-4">
                    <AvRadio value={answer.valoration} />
                  </div>
                ))}
              </AvRadioGroup>
            </Col>
            {answers.length > 0 && (
              <Col md={11}>
                {answers.map((answer, index) => (
                  <Row
                    key={`answer-text-${answer.idQuizAnswer}`}
                    className="mb-2"
                  >
                    <Col xs={12} className="d-flex align-items-center">
                      <AvInput
                        onBlur={e =>
                          handleUpdateAnswerName(
                            answer.idQuizAnswer,
                            e.target.value
                          )
                        }
                        name={`answer-${answer.idQuizAnswer}`}
                        className="form-control"
                        placeholder={`Ingresa la respuesta ${index + 1}`}
                        value={""}
                        type="text"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Este campo es requerido",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                ))}
              </Col>
            )}
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              props.onClose()
            }}
          >
            Regresar
          </button>
          <button type="submit" className="btn btn-secondary">
            Agregar pregunta
          </button>
        </div>
      </AvForm>
      <style jsx="true">{`
        .noNorder {
          border: 0 !important;
        }
      `}</style>
    </Modal>
  )
}

QuizzFormModal.propTypes = {
  show: PropTypes.bool,
  course: PropTypes.object,
  quizz: PropTypes.object,
  onClose: PropTypes.func,
  reload: PropTypes.func,
}

export default QuizzFormModal
