import { useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Row,
  Col,
  CardImg,
  Progress,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Button,
} from "reactstrap"
import fester from "../../assets/images/logo_fester_header.png"

import {
  addFav,
  addFavClean,
  removeFav,
  removeFavClean,
  getCoursesByUser,
} from "store/actions"

const CourseInfo = props => {
  const [dropDowns, setDropDowns] = useState(false)
  const [modal, setModal] = useState(false)

  const { addFavDone, removeFavDone, user } = useSelector(store => ({
    addFavDone: store.FavAdd.done,
    removeFavDone: store.FavRemove.done,
    user: store.Profile.user,
  }))

  const idUser = user?.idUsuario ?? 0

  const dispatch = useDispatch()

  const handleAddFav = idCourse => {
    dispatch(addFav(idCourse))
  }

  const handleRemoveFav = idCourse => {
    dispatch(removeFav(idCourse))
  }

  if (addFavDone) {
    dispatch(getCoursesByUser(idUser))
    dispatch(addFavClean())
  }

  if (removeFavDone) {
    dispatch(getCoursesByUser(idUser))
    dispatch(removeFavClean())
  }

  return (
    <Card>
      <CardBody>
        <Row className="no-gutters align-items-center">
          <Col md={3} className="align-self-start">
            <CardImg
              className="img-fluid"
              src={props.image == "" ? fester : props.image}
              alt="Imagen"
            />
            {props.finished && (
              <div className="text-center mt-1">
                <button className="btn btn-link">
                  <h5>
                    <i className="fa fa-comment"></i> Comentarios
                  </h5>
                </button>
              </div>
            )}
          </Col>
          <Col md={9} className="align-self-start">
            <div className="d-flex justify-content-between">
              <h3>{props.title}</h3>
              {props.finished && (
                <div>
                  <span
                    className="badge bg-pill bg-success"
                    style={{ fontSize: "16px", borderRadius: "14px" }}
                  >
                    {" "}
                    <i className="fa fa-flag-checkered"></i> Aprobado
                  </span>
                </div>
              )}
            </div>
            <h5 className="alt-celf-color">{props.category}</h5>
            <hr />
            {props.started && (
              <p>
                * {props.finished ? "Inició" : "Inicio"}: 1 de octubre de 2021 a
                las 12:00:00 hrs.
              </p>
            )}
            {props.started && (
              <p>
                * {props.finished ? "Finalizó" : "Último ingreso"}: 1 de
                diciembre de 2021 a las 12:00:00 hrs.
              </p>
            )}
            {!props.started && (
              <p>
                El objetivo del curso es brindar al alumno la información
                necesaria para poder realizar la reparación de estructuras con
                ayuda de productos enfocados al concreto.
              </p>
            )}
            <Row>
              {props.started && (
                <Col md={props.finished ? 4 : 8}>
                  <div className="d-flex justify-content-center">
                    <Progress
                      color="primary"
                      value={props.percentage}
                      style={{ width: "100%", height: "16px" }}
                    />
                  </div>
                  <div className="d-flex justify-content-center mt-1">
                    {!props.finished && (
                      <p>
                        {props.percentage}% avance - {props.minutes} minutos
                        dedicados
                      </p>
                    )}
                    {props.finished && <p>{props.minutes} minutos dedicados</p>}
                  </div>
                </Col>
              )}
              {!props.started && (
                <Col md={8} className="pt-2">
                  <i className="fa fa-clock" /> Duración del curso:{" "}
                  <strong>{props.minutes} minutos</strong>
                </Col>
              )}
              {props.finished && (
                <Col md={4}>
                  <div className="text-center">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="far fa-star" />
                  </div>
                  <p className="text-center">Valoración</p>
                </Col>
              )}
              <Col md={4}>
                <Dropdown
                  className="float-start"
                  isOpen={dropDowns}
                  toggle={() => {
                    setDropDowns(!dropDowns)
                  }}
                >
                  <DropdownToggle color="light" className="btn btn-light">
                    <i className="fa fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="white-celf-background">
                    <DropdownItem
                      onClick={() => {
                        props.fav
                          ? handleRemoveFav(props.id)
                          : handleAddFav(props.id)
                      }}
                    >
                      <i className={`fa${props.fav ? "r" : ""} fa-star`} />{" "}
                      {props.fav
                        ? "Remover de favoritos"
                        : "Añadir a favoritos"}
                    </DropdownItem>
                    <DropdownItem onClick={() => setModal(true)}>
                      <i className="fa fa-share-alt" /> Compartir
                    </DropdownItem>
                    {props.started && (
                      <DropdownItem>
                        <i className="fa fa-sync-alt" /> Resetear cursos
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
                <div className="float-end" style={{ width: "20px" }}>
                  &nbsp;
                </div>
                <Link
                  to={`/courses/info/${props.id}`}
                  className="btn btn-primary float-end"
                >
                  Ir al curso <i className="fa fa-arrow-right"></i>
                </Link>
              </Col>
            </Row>
            {props.finished && (
              <Row>
                <Col className="text-center">
                  <button className="btn btn-link">
                    Habilidades adquiridas
                  </button>
                </Col>
                <Col className="text-center">
                  <button className="btn btn-link">
                    Conocimientos adquiridos
                  </button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </CardBody>
      <Modal isOpen={modal} backdrop={"static"} id="staticBackdrop">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Compartir este curso
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p>
            ¿Deseas compartir este curso con tus compañeros de la Capacitación
            en Línea Fester?
          </p>
          <Row>
            <Col md={1}>&nbsp;</Col>
            <Col md={2} className="text-center">
              <i className="fab fa-facebook" style={{ fontSize: 32 }}></i>
              <p>Facebook</p>
            </Col>
            <Col md={2} className="text-center">
              <i className="fab fa-linkedin" style={{ fontSize: 32 }}></i>
              <p>LinkedIn</p>
            </Col>
            <Col md={2} className="text-center">
              <i className="fab fa-twitter" style={{ fontSize: 32 }}></i>
              <p>Twitter</p>
            </Col>
            <Col md={2} className="text-center">
              <i className="fab fa-whatsapp" style={{ fontSize: 32 }}></i>
              <p>WhatsApp</p>
            </Col>
            <Col md={2} className="text-center">
              <i className="fa fa-envelope" style={{ fontSize: 32 }}></i>
              <p>
                Correo
                <br />
                electrónico
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={9}>
              <button
                className="btn btn-link btn-block"
                style={{ background: "#eee" }}
              >
                {`/courses/info/${props.id}`}
              </button>
            </Col>
            <Col md={3}>
              <button className="btn btn-primary btn-block">
                <i className="fa fa-copy"></i> Copiar
              </button>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setModal(false)
            }}
          >
            Cancelar
          </button>
        </div>
      </Modal>
    </Card>
  )
}

CourseInfo.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  category: PropTypes.string,
  image: PropTypes.string,
  percentage: PropTypes.number,
  minutes: PropTypes.number,
  fav: PropTypes.bool,
  started: PropTypes.bool,
  finished: PropTypes.bool,
}

export default CourseInfo
