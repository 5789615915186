import { all, fork } from "redux-saga/effects"

//public
import LayoutSaga from "./layout/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"

// Users
import usersListSaga from "./users/list/saga"
import userAddSaga from "./users/add/saga"
import userEditSaga from "./users/edit/saga"

// Profiles
import profilesListSaga from "./profiles/saga"

// Customers
import customersListSaga from "./customers/list/saga"

// Groups
import groupsListSaga from "./groups/list/saga"
import groupAddSaga from "./groups/add/saga"
import groupGetSaga from "./groups/get/saga"
import groupEditSaga from "./groups/edit/saga"
import groupParticipantAddSaga from "./groups/add_participant/saga"
import groupParticipantsListSaga from "./groups/get_participants/saga"
import groupParticipantRemoveSaga from "./groups/remove_participant/saga"
import groupCourseAddSaga from "./groups/add_course/saga"
import groupCoursesListSaga from "./groups/get_courses/saga"
import groupCourseRemoveSaga from "./groups/remove_course/saga"

// Positions
import positionsListSaga from "./positions/list/saga"
import positionAddSaga from "./positions/add/saga"
import positionEditSaga from "./positions/edit/saga"

// Businesses
import businessesListSaga from "./businesses/list/saga"
import businessAddSaga from "./businesses/add/saga"
import businessEditSaga from "./businesses/edit/saga"
import businessGetSaga from "./businesses/get/saga"

// Support material types
import supportMaterialTypesListSaga from "./support-material-types/list/saga"
import supportMaterialTypeAddSaga from "./support-material-types/add/saga"
import supportMaterialTypeEditSaga from "./support-material-types/edit/saga"
import supportMaterialTypeGetSaga from "./support-material-types/get/saga"

// Knowledge categories
import knowledgeCategoriesListSaga from "./knowledge-categories/list/saga"
import knowledgeCategoryAddSaga from "./knowledge-categories/add/saga"
import knowledgeCategoryEditSaga from "./knowledge-categories/edit/saga"

// Skills
import skillsListSaga from "./skills/list/saga"
import skillAddSaga from "./skills/add/saga"
import skillEditSaga from "./skills/edit/saga"

// Levels
import levelsListSaga from "./levels/list/saga"
import levelAddSaga from "./levels/add/saga"
import levelEditSaga from "./levels/edit/saga"

// Course knowledges
import courseKnowledgesListSaga from "./course-knowledges/list/saga"
import courseKnowledgeAddSaga from "./course-knowledges/add/saga"
import courseKnowledgeEditSaga from "./course-knowledges/edit/saga"

// Courses
import coursesSaga from "./courses/list/saga"
import courseGetSaga from "./courses/get/saga"
import courseAddSaga from "./courses/add/saga"
import courseEditSaga from "./courses/edit/saga"

// Lessons
import lessonsSaga from "./lessons/list/saga"
import lessonGetSaga from "./lessons/get/saga"
import lessonAddSaga from "./lessons/add/saga"
import lessonEditSaga from "./lessons/edit/saga"
import lessonRemoveSaga from "./lessons/remove/saga"

// Blocks
import blocksSaga from "./blocks/list/saga"
//import blockGetSaga from "./blocks/get/saga"
import blockAddSaga from "./blocks/add/saga"
import blockEditSaga from "./blocks/edit/saga"
import blockRemoveSaga from "./blocks/remove/saga"

// Support materials
import supportMaterialsListSaga from "./support-materials/list/saga"
import supportMaterialAddSaga from "./support-materials/add/saga"
import supportMaterialRemoveSaga from "./support-materials/remove/saga"

// Shoppnig Areas
import shoppingAreasListSaga from "./shopping-areas/list/saga"
import shoppingAreasAddSaga from "./shopping-areas/add/saga"
import shoppingAreasEditSaga from "./shopping-areas/edit/saga"

// Quizzes
import quizzListSaga from "./quizzes/list/saga"
import quizzAddSaga from "./quizzes/add/saga"
import quizzAnswerAddSaga from "./quizzes/add_answer/saga"
import quizzAnswerEditSaga from "./quizzes/edit_answer/saga"

// Exams
import examAddSaga from "./exams/add/saga"
import examGetSaga from "./exams/get/saga"
import examEditSaga from "./exams/edit/saga"

// Questions
import questionAddSaga from "./questions/add/saga"
import questionEditSaga from "./questions/edit/saga"
import questionGetSaga from "./questions/get/saga"
import questionsListSaga from "./questions/list/saga"
import questionRemoveSaga from "./questions/remove/saga"

// Answers
import answersAddSaga from "./answers/add/saga"
import answerEditSaga from "./answers/edit/saga"
import answersListSaga from "./answers/list/saga"
import answerGetSaga from "./answers/get/saga"

// Poll
import pollsListSaga from "./polls/list/saga"
import pollAddSaga from "./polls/add/saga"
import pollEditSaga from "./polls/edit/saga"

// Notes
import notesListSaga from "./notes/list/saga"
import noteAddSaga from "./notes/add/saga"
import noteRemoveSaga from "./notes/remove/saga"

// Images
import imageAddSaga from "./images/add/saga"

// Favs
import favAddSaga from "./favs/add/saga"
import favRemoveSaga from "./favs/remove/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(ForgetSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(usersListSaga),
    fork(userAddSaga),
    fork(userEditSaga),
    fork(profilesListSaga),
    fork(customersListSaga),
    fork(groupsListSaga),
    fork(groupAddSaga),
    fork(groupGetSaga),
    fork(groupEditSaga),
    fork(groupParticipantAddSaga),
    fork(groupParticipantsListSaga),
    fork(groupParticipantRemoveSaga),
    fork(groupCourseAddSaga),
    fork(groupCoursesListSaga),
    fork(groupCourseRemoveSaga),
    fork(positionsListSaga),
    fork(positionAddSaga),
    fork(positionEditSaga),
    fork(businessesListSaga),
    fork(businessAddSaga),
    fork(businessEditSaga),
    fork(businessGetSaga),
    fork(supportMaterialTypesListSaga),
    fork(supportMaterialTypeAddSaga),
    fork(supportMaterialTypeEditSaga),
    fork(supportMaterialTypeGetSaga),
    fork(shoppingAreasListSaga),
    fork(shoppingAreasAddSaga),
    fork(shoppingAreasEditSaga),
    fork(coursesSaga),
    fork(courseGetSaga),
    fork(courseAddSaga),
    fork(courseEditSaga),
    fork(lessonsSaga),
    fork(lessonGetSaga),
    fork(lessonAddSaga),
    fork(lessonEditSaga),
    fork(lessonRemoveSaga),
    fork(blocksSaga),
    fork(blockAddSaga),
    fork(blockEditSaga),
    fork(blockRemoveSaga),
    fork(supportMaterialsListSaga),
    fork(supportMaterialAddSaga),
    fork(supportMaterialRemoveSaga),
    fork(knowledgeCategoriesListSaga),
    fork(knowledgeCategoryAddSaga),
    fork(knowledgeCategoryEditSaga),
    fork(skillsListSaga),
    fork(skillAddSaga),
    fork(skillEditSaga),
    fork(levelsListSaga),
    fork(levelAddSaga),
    fork(levelEditSaga),
    fork(courseKnowledgesListSaga),
    fork(courseKnowledgeAddSaga),
    fork(courseKnowledgeEditSaga),
    fork(imageAddSaga),
    fork(quizzListSaga),
    fork(quizzAddSaga),
    fork(quizzAnswerAddSaga),
    fork(quizzAnswerEditSaga),
    fork(examAddSaga),
    fork(examGetSaga),
    fork(examEditSaga),
    fork(questionAddSaga),
    fork(questionEditSaga),
    fork(questionGetSaga),
    fork(questionsListSaga),
    fork(questionRemoveSaga),
    fork(answersAddSaga),
    fork(answerEditSaga),
    fork(answersListSaga),
    fork(answerGetSaga),
    fork(favAddSaga),
    fork(favRemoveSaga),
    fork(pollsListSaga),
    fork(pollAddSaga),
    fork(pollEditSaga),
    fork(notesListSaga),
    fork(noteAddSaga),
    fork(noteRemoveSaga),
  ])
}
