import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "common/moment_i18n"

import {
  listGroupParticipants,
  addGroupParticipant,
  addGroupParticipantClean,
  removeGroupParticipant,
  removeGroupParticipantClean,
  getUsers,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import AddUserToGroupModal from "./AddUserToGroupModal"
import DeleteUserFromGroupModal from "./DeleteUserFromGroupModal"

const UsersGroupListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [showAddParticipant, setShowAddParticipant] = useState(false)
  const [showDeleteParticipant, setShowDeleteParticipant] = useState(undefined)
  const [usuario, setUsuario] = useState({
    idUsuario: 0,
  })

  useEffect(() => {
    dispatch(listGroupParticipants(props.group.idGrupo))
    dispatch(getUsers())
  }, [])

  const {
    groupParticipants,
    addParticipantLoading,
    addParticipantError,
    addParticipantDone,
    removeParticipantLoading,
    removeParticipantError,
    removeParticipantDone,
    usersList,
  } = useSelector(store => ({
    groupParticipants: store.GroupParticipantsList.groupParticipants,
    addParticipantLoading: store.GroupParticipantAdd.loading,
    addParticipantError: store.GroupParticipantAdd.error,
    addParticipantDone: store.GroupParticipantAdd.done,
    removeParticipantLoading: store.GroupParticipantRemove.loading,
    removeParticipantError: store.GroupParticipantRemove.error,
    removeParticipantDone: store.GroupParticipantRemove.done,
    usersList: store.UsersList.users,
  }))

  let users = []
  let availableUsers = []

  if (usersList !== undefined && groupParticipants !== undefined) {
    usersList.forEach(user => {
      const exists = groupParticipants.find(
        participant => participant.idUser === user.idUsuario
      )
      if (exists !== undefined) {
        user.groupUserPercentage = exists.groupUserPercentage
        user.completeCourses = exists.completeCourses
        user.totalCourses = exists.totalCourses
        users.push(user)
      } else {
        availableUsers.push(user)
      }
    })
  }

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.idUsuario}`}
        isOpen={row.idUsuario === active}
        toggle={() => {
          setDropDown(row.idUsuario === active ? 0 : row.idUsuario)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem>Info de usuario</DropdownItem>
          <DropdownItem>Enviar notificación</DropdownItem>
          <DropdownItem>Resetear cursos</DropdownItem>
          <DropdownItem onClick={() => setShowDeleteParticipant(row)}>
            Eliminar del grupo
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  const columns = [
    {
      dataField: "idUsuario",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "nombre",
      text: "Nombre completo y correo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`nombre-${row.idUsuario}`}>
            <strong>
              {row.nombre} {row.apellido}
            </strong>
            <br />
            <span>{row.email}</span>
          </div>
        )
      },
    },
    {
      dataField: "progreso",
      text: "Progreso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        const progreso = row.groupUserPercentage
        const restante = 100 - progreso
        const terminados = row.completeCourses
        const cursos = row.totalCourses
        return (
          <div key={`progress-${row.idUsuario}`}>
            <Progress multi>
              <Progress
                bar
                value={progreso}
                color="secondary"
                style={{ width: `${progreso}%` }}
                animated
              />
              <Progress
                bar
                value={restante}
                color="light"
                style={{ width: `${restante}%` }}
              />
            </Progress>
            <div className="mt-1">{`${
              Math.round((progreso + Number.EPSILON) * 100) / 100
            }%`}</div>
            <div>
              {terminados} / {cursos}
            </div>
          </div>
        )
      },
    },
    {
      dataField: "lastActivity",
      text: "Último ingreso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <span key={`time-${row.idUsuario}`}>
            {moment(row.lastActivity)
              .locale("es")
              .format("D [de] MMMM [de] YYYY")}
          </span>
        )
      },
    },
    {
      dataField: "idRol",
      text: "Perfil",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return <span>N{row.idRol}</span>
      },
    },
    {
      dataField: "idStatus",
      text: "Estatus",
      sort: true,
      align: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.idUsuario}`}>
            <span
              className={`pt-2 badge bg-${
                row.idStatus ? "success" : "warning"
              }`}
            >
              {row.idStatus ? "Activo" : "Inactivo"}
            </span>
          </h4>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      formatter: dropDownActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: users?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "idRol", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  const handleAddUser = userId => {
    const request = {
      idGroup: props.group.idGrupo,
      idUser: userId,
    }

    dispatch(addGroupParticipant(request))
  }

  const handleDeleteUser = userId => {
    const request = {
      idGroup: props.group.idGrupo,
      idUser: userId,
    }

    dispatch(removeGroupParticipant(request))
  }

  if (addParticipantDone) {
    dispatch(listGroupParticipants(props.group.idGrupo))
    dispatch(addGroupParticipantClean())
  }

  if (removeParticipantDone) {
    setShowDeleteParticipant(undefined)
    dispatch(listGroupParticipants(props.group.idGrupo))
    dispatch(removeGroupParticipantClean())
  }

  return (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="idUsuario"
        columns={columns}
        data={users}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="idUsuario"
            columns={columns}
            data={users}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  <Col className="text-center">
                    <button className="btn btn-link">
                      <i className="fa fa-file-excel"></i> Exportar a Excel
                    </button>
                    {"   "}
                    <Link to="/users" className="btn btn-link">
                      <i className="fa fa-users"></i> Ver todos los usuarios
                    </Link>
                    {"   "}
                    <button className="btn btn-link">
                      <i className="fa fa-sync-alt"></i> Resetear cursos
                    </button>
                  </Col>
                </Row>
                <Row className="mb-2 pt-3">
                  <Col xl={2} lg={3} md={4} sm={4} xs={6}>
                    <div className="d-grid">
                      <button
                        onClick={() => setShowAddParticipant(true)}
                        className="btn btn-primary btn-rounded"
                      >
                        <i className="fa fa-plus"></i> Agregar participante
                      </button>
                    </div>
                  </Col>
                  <Col
                    xl={{ size: 2, offset: 8 }}
                    lg={{ size: 3, offset: 6 }}
                    md={{ size: 4, offset: 4 }}
                    sm={{ size: 4, offset: 4 }}
                    xs={6}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Fragment>
                      <BootstrapTable
                        keyField={"idUsuario"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                      {users.length <= 0 && (
                        <Row>
                          <Col md={12} className="text-center">
                            -- No hay registros --
                          </Col>
                        </Row>
                      )}
                    </Fragment>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      <AddUserToGroupModal
        show={showAddParticipant}
        loading={addParticipantLoading}
        users={availableUsers}
        onConfirm={userId => handleAddUser(userId)}
        onCancel={() => setShowAddParticipant(false)}
        error={addParticipantError}
      />
      <DeleteUserFromGroupModal
        show={showDeleteParticipant !== undefined}
        user={showDeleteParticipant}
        loading={removeParticipantLoading}
        onConfirm={userId => handleDeleteUser(userId)}
        onCancel={() => setShowDeleteParticipant(undefined)}
        error={removeParticipantError}
      />
    </Fragment>
  )
}

UsersGroupListTable.propTypes = {
  group: PropTypes.object,
}

export default UsersGroupListTable
