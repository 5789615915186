/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_QUIZZ_ANSWER } from "./actionTypes"
import { apiError, addQuizzAnswerSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addQuizzAnswer } from "../../../helpers/backend_helper"

function* add(request) {
  try {
    const response = yield call(addQuizzAnswer, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(addQuizzAnswerSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* quizzAnswerAddSaga() {
  yield takeEvery(ADD_QUIZZ_ANSWER, add)
}

export default quizzAnswerAddSaga
