import { call, put, takeEvery } from "redux-saga/effects"
import * as Sentry from "@sentry/browser"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

import { postJwtLogin } from "../../../helpers/backend_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      Username: user.email,
      Password: user.password,
    })
    if (response === undefined) {
      yield put(
        apiError(
          "El nombre de usuario o la contraseña ingresados son incorrectos."
        )
      )
      return
    }
    if (response.success) {
      Sentry.setUser({ email: user.email })
      localStorage.setItem("authUser", response.token)
      yield put(loginSuccess(response))
      history.push("/dashboard")
    } else if (response.coldDown) {
      yield put(
        apiError(
          "Por seguridad hemos bloqueado tu cuenta. Enviamos a tu bandeja de correo las instrucciones para desbloquearla."
        )
      )
    } else {
      yield put(
        apiError(
          "El nombre de usuario o la contraseña ingresados son incorrectos."
        )
      )
    }
  } catch (error) {
    console.log(error)
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    Sentry.configureScope(scope => scope.setUser(null))
    localStorage.removeItem("authUser")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
