import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useSelector, useDispatch } from "react-redux"
import { withRouter, Link, Redirect } from "react-router-dom"
import { me, meReset } from "../../../store/actions"

// users
import user1 from "../../../assets/images/user_without_pic.png"

const ProfileMenu = props => {
  const dispatch = useDispatch()
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  const { whoIAm, profileError } = useSelector(state => ({
    whoIAm: state.Profile.user,
    profileError: state.Profile.profileError,
  }))

  const user =
    whoIAm === undefined
      ? {
          id: 0,
          nombre: "",
          apellido: "",
          idRol: 0,
        }
      : whoIAm

  useEffect(() => {
    dispatch(me())
  }, [props.success])

  if (whoIAm === undefined && profileError != "") {
    dispatch(meReset())
    return <Redirect to="/logout" />
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item  mt-2"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {user.nombre} - N{user.idRol}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Mi cuenta
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <i className="fa fa-globe-americas font-size-16 align-middle me-1" />
            Idioma
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end pt-1">2</span>
            <i className="fa fa-trophy font-size-16 align-middle me-1" />
            Insignias
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Cerrar sesión</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.object,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
