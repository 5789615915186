import { useState } from "react"
import { PropTypes } from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import CourseInfo from "components/Dashboard/CourseInfo"

const CoursesByStatus = props => {
  const myCourses = props.courses

  const coursesInProgress = myCourses.filter(
    course =>
      course.estatusCurso === 2 &&
      course.CourseParticipant?.advancePercentage < 100 &&
      course.CourseParticipant?.advancePercentage > 0
  )
  const coursesPending = myCourses.filter(
    course =>
      course.estatusCurso === 2 &&
      course.CourseParticipant?.advancePercentage <= 0
  )
  const coursesCompleted = myCourses.filter(
    course =>
      course.estatusCurso === 2 &&
      course.CourseParticipant?.advancePercentage >= 100
  )
  const coursesFav = myCourses.filter(
    course =>
      course.estatusCurso === 2 && course.CourseParticipant?.favourite === true
  )
  const [activeTab, setActiveTab] = useState("1")

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Card>
      <CardBody>
        <Nav className="icon-tab nav-justified">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleIconCustom("1")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos en progreso ({coursesInProgress.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggleIconCustom("2")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos pendientes ({coursesPending.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                toggleIconCustom("3")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos completados ({coursesCompleted.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "4",
              })}
              onClick={() => {
                toggleIconCustom("4")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos favoritos ({coursesFav.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="p-3 text-muted mt-4">
          <TabPane tabId="1">
            {coursesInProgress.length <= 0 && (
              <Row>
                <Col lg={12} class="text-center">
                  <h5 className="text-center">Sin cursos en progreso...</h5>
                </Col>
              </Row>
            )}
            {coursesInProgress.map(course => (
              <Row key={`course-progress-${course.idCurso}`}>
                <Col lg={12}>
                  <CourseInfo
                    id={course.idCurso}
                    title={course.nombreCurso}
                    category={course.knowledgeCategories
                      .map(category => category.knowledgeCategory.trim())
                      .join(", ")}
                    percentage={course.CourseParticipant.advancePercentage}
                    minutes={course.tiempoDedicacion}
                    image={course.coverImage ?? ""}
                    fav={course.CourseParticipant.favourite}
                    started={course.CourseParticipant.advancePercentage > 0}
                    finished={course.CourseParticipant.advancePercentage >= 100}
                  />
                </Col>
              </Row>
            ))}
          </TabPane>
          <TabPane tabId="2">
            {coursesPending.length <= 0 && (
              <Row>
                <Col lg={12} class="text-center">
                  <h5 className="text-center">Sin cursos pendientes...</h5>
                </Col>
              </Row>
            )}
            {coursesPending.map(course => (
              <Row key={`course-pending-${course.idCurso}`}>
                <Col lg={12}>
                  <CourseInfo
                    id={course.idCurso}
                    title={course.nombreCurso}
                    category={course.knowledgeCategories
                      .map(category => category.knowledgeCategory.trim())
                      .join(", ")}
                    percentage={course.CourseParticipant.advancePercentage}
                    minutes={course.tiempoDedicacion}
                    image={course.coverImage ?? ""}
                    fav={course.CourseParticipant.favourite}
                    started={course.CourseParticipant.advancePercentage > 0}
                    finished={course.CourseParticipant.advancePercentage >= 100}
                  />
                </Col>
              </Row>
            ))}
          </TabPane>
          <TabPane tabId="3">
            <Col lg={12}>
              {coursesCompleted.length <= 0 && (
                <Row>
                  <Col lg={12} class="text-center">
                    <h5 className="text-center">Sin cursos completados...</h5>
                  </Col>
                </Row>
              )}
              {coursesCompleted.map(course => (
                <Row key={`course-pending-${course.idCurso}`}>
                  <Col lg={12}>
                    <CourseInfo
                      id={course.idCurso}
                      title={course.nombreCurso}
                      category={course.knowledgeCategories
                        .map(category => category.knowledgeCategory.trim())
                        .join(", ")}
                      percentage={course.CourseParticipant.advancePercentage}
                      minutes={course.tiempoDedicacion}
                      image={course.coverImage ?? ""}
                      fav={course.CourseParticipant.favourite}
                      started={course.CourseParticipant.advancePercentage > 0}
                      finished={
                        course.CourseParticipant.advancePercentage >= 100
                      }
                    />
                  </Col>
                </Row>
              ))}
            </Col>
          </TabPane>
          <TabPane tabId="4">
            {coursesFav.length <= 0 && (
              <Row>
                <Col lg={12} class="text-center">
                  <h5 className="text-center">Sin cursos favoritos...</h5>
                </Col>
              </Row>
            )}
            {coursesFav.map(course => (
              <Row key={`course-pending-${course.idCurso}`}>
                <Col lg={12}>
                  <CourseInfo
                    id={course.idCurso}
                    title={course.nombreCurso}
                    category={course.knowledgeCategories
                      .map(category => category.knowledgeCategory.trim())
                      .join(", ")}
                    percentage={course.CourseParticipant.advancePercentage}
                    minutes={course.tiempoDedicacion}
                    image={course.coverImage ?? ""}
                    fav={course.CourseParticipant.favourite}
                    started={course.CourseParticipant.advancePercentage > 0}
                    finished={course.CourseParticipant.advancePercentage >= 100}
                  />
                </Col>
              </Row>
            ))}
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  )
}

CoursesByStatus.propTypes = {
  courses: PropTypes.array,
}

export default CoursesByStatus
