import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import { addSupportMaterial } from "store/actions"

const SupportMaterialModal = props => {
  const dispatch = useDispatch()
  const id = props.supportMaterial?.idCourseSupportMaterial ?? 0
  const [formError, setFormError] = useState(false)
  const [file, setFile] = useState(undefined)

  const { loading, done, error } = useSelector(state => ({
    loading: state.SupportMaterialAdd.loading,
    done: state.SupportMaterialAdd.done,
    error: state.SupportMaterialAdd.error,
  }))

  const handleSubmit = async (event, errors, values) => {
    if (errors.length > 0 || file === undefined) {
      setFormError(true)
      return
    }
    setFormError(false)

    const formData = new FormData()
    formData.append("file", file)

    const request = {
      title: values.title,
      idCourse: props.course.idCurso,
      idSupportMaterialType: values.idSupportMaterialType,
    }

    dispatch(addSupportMaterial(formData, request))
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {id === 0 ? "Registrar" : "Editar"} material de apoyo
          </h5>
          {!loading && (
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                props.onCancel()
              }}
              aria-label="Close"
            ></button>
          )}
        </div>
        <div className="modal-body">
          {props.error !== "" && (
            <div className="alert alert-danger">{props.error}</div>
          )}
          <Row className="mb-3">
            <Col md={12}>
              <AvField
                name="title"
                label="Título"
                value={id == 0 ? "" : props.supportMaterial.title}
                className="form-control"
                placeholder=""
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <AvField
                name="idSupportMaterialType"
                label="Tipo"
                value={
                  id == 0 ? "" : props.supportMaterial.idSupportMaterialType
                }
                className="form-control"
                placeholder=""
                type="select"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option value="">-- Seleccione tipo --</option>
                {props.supportMaterialTypes !== undefined &&
                  props.supportMaterialTypes
                    .filter(supportMaterialType => supportMaterialType.status)
                    .map(supportMaterialType => {
                      return (
                        <option
                          value={supportMaterialType.idSupportMaterialType}
                          key={`support-material-type-${supportMaterialType.idSupportMaterialType}`}
                        >
                          {supportMaterialType.SupportMaterialType}
                        </option>
                      )
                    })}
              </AvField>
            </Col>
          </Row>
          <Row className="mb-3 mt-4">
            <Col md={12}>
              <Input
                type="file"
                className="form-control"
                required={true}
                id="file"
                aria-label="Seleccionar"
                onChange={e => setFile(e.target.files[0])}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          {!loading && (
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                props.onCancel()
              }}
            >
              Cancelar
            </button>
          )}
          <button
            type="submit"
            className={`btn btn-secondary ${loading ? "disabled" : ""}`}
          >
            {loading ? "Cargando, por favor espera..." : "Agregar"}
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

SupportMaterialModal.propTypes = {
  course: PropTypes.object,
  show: PropTypes.bool,
  supportMaterial: PropTypes.object,
  supportMaterialTypes: PropTypes.array,
  error: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default SupportMaterialModal
