import React from "react"
import { Container, Row, Col } from "reactstrap"
import facebookIcon from "../../assets/images/facebook_icon.png"
import linkedInIcon from "../../assets/images/linkedin_icon.png"
import youtubeIcon from "../../assets/images/youtube_icon.png"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={4}>
              {new Date().getFullYear()} © Henkel | Términos y Condiciones |
              Políticas de Privacidad
            </Col>
            <Col md={4} className="text-center">
              <img
                src={linkedInIcon}
                alt="LinkedIn"
                className="img-fluid"
                width="20"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={facebookIcon}
                alt="Facebook"
                className="img-fluid"
                width="20"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={youtubeIcon}
                alt="YouTube"
                className="img-fluid"
                width="20"
              />
            </Col>
            <Col md={4}>
              <div className="text-sm-end d-none d-sm-block">
                Desarrollado por Faraday Services
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
