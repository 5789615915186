import { Fragment, useState, useEffect, useRef } from "react"
import { PropTypes } from "prop-types"
import { Col, Row, Media, Input, Modal, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getBlocks } from "store/actions"

const CoursesLessonsDetails = props => {
  const [modal, setModal] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState(0)

  const lessons = props.lessons ?? []

  const dispatch = useDispatch()
  const ref = useRef(null)

  const { blocks } = useSelector(store => ({
    blocks: store.BlocksList.blocks,
  }))

  useEffect(() => {
    const idLesson = lessons[selectedLesson]?.idLesson
    if (idLesson !== undefined)
      dispatch(getBlocks(lessons[selectedLesson]?.idLesson))
    return () => setSelectedLesson(0)
  }, [lessons])

  const handleChangeLesson = newLesson => {
    setSelectedLesson(newLesson)
    dispatch(getBlocks(props.lessons[newLesson].idLesson))
    if (ref.current)
      ref.current.scrollIntoView({
        behavior: "smooth",
      })
  }

  return (
    <Fragment>
      <div
        style={{ position: "absolute", top: -50, left: 0, right: 0 }}
        ref={ref}
      ></div>
      <Row>
        <Col md={3}>
          <ul className="verti-timeline list-unstyled">
            {lessons
              .sort((a, b) => (a.order > b.order ? 1 : -1))
              .map((lesson, index) => (
                <li
                  key={`lesson-${index}`}
                  className="event-list"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedLesson(index)}
                >
                  <div className="event-timeline-dot">
                    <i
                      className={`fa fa-circle font-size-18 ${
                        index <= selectedLesson ? "alt-celf-color" : ""
                      }`}
                    />
                  </div>
                  <Media>
                    <Media body>
                      <h3
                        className={
                          index <= selectedLesson ? "alt-celf-color" : ""
                        }
                      >
                        {lesson.title}
                      </h3>
                      <span>Lección {index + 1}</span>
                    </Media>
                  </Media>
                </li>
              ))}
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="fa fa-circle font-size-18" />
              </div>
              <Media>
                <Media body>
                  <h3>Examen</h3>
                </Media>
              </Media>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="fa fa-circle font-size-18" />
              </div>
              <Media>
                <Media body>
                  <h3>Resumen</h3>
                </Media>
              </Media>
            </li>
          </ul>
        </Col>
        <Col md={6}>
          {blocks !== undefined &&
            blocks.map((block, index) => {
              if (block.type == "text") {
                return (
                  <Row className=" border pt-3 pb-3">
                    <Col>
                      <h3>{props.lessons[selectedLesson].title}</h3>
                      <hr />
                      {blocks !== undefined &&
                        blocks.map((block, index) => {
                          if (block.type == "text") {
                            return (
                              <Card className="card">
                                <CardBody>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: block.value,
                                    }}
                                  ></div>
                                </CardBody>
                              </Card>
                            )
                          }
                        })}
                    </Col>
                  </Row>
                )
              }
              if (block.tyoe == "youtube") {
                var video_id = block.value.split("v=")[1]
                var ampersandPosition = video_id.indexOf("&")
                if (ampersandPosition != -1) {
                  video_id = video_id.substring(0, ampersandPosition)
                }
                return (
                  <iframe
                    width="100%"
                    height="480"
                    src={`https://www.youtube.com/embed/${video_id}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                )
              }
            })}
          <div className="mt-4 d-flex justify-content-center">
            {selectedLesson > 0 && (
              <button
                onClick={() => handleChangeLesson(selectedLesson - 1)}
                className="btn btn-light mr-2"
              >
                <i className="fa fa-arrow-left"></i> Lección anterior
              </button>
            )}
            {"  "}
            {props.lessons.length > selectedLesson + 1 && (
              <button
                onClick={() => handleChangeLesson(selectedLesson + 1)}
                className="btn btn-secondary ml-2"
              >
                Siguiente lección <i className="fa fa-arrow-right"></i>
              </button>
            )}
          </div>
        </Col>
        <Col md={3}>
          <div className="border p-4">
            <h3>Agregar nota</h3>
            <Input type="textarea" placeholder="Escribe una nota" />
            <div className="text-center pt-2">
              <button className="btn btn-primary">Agregar</button>
            </div>
          </div>
        </Col>
      </Row>
      <Modal isOpen={modal} backdrop={"static"} id="staticBackdrop">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            ¿Deseas continuar donde te quedaste?
          </h5>
        </div>
        <div className="modal-body">
          <p>
            ¿Deseas continuar el curso donde te quedaste la última vez que
            ingresaste al curso o quieres regresar al inicio?
          </p>
        </div>
        <div className="modal-footer">
          <a type="button" className="btn btn-light" href="/courses/intro/1">
            Regresar al inicio
          </a>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setModal(false)
            }}
          >
            Continuar aquí
          </button>
        </div>
      </Modal>
    </Fragment>
  )
}

CoursesLessonsDetails.propTypes = {
  course: PropTypes.object,
  lessons: PropTypes.array,
}

export default CoursesLessonsDetails
