import { Fragment, useEffect, useState } from "react"
import { Col, Row, Label, Button, Input, Modal } from "reactstrap"
import PropTypes from "prop-types"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import { useDispatch, useSelector } from "react-redux"
import {
  getLessons,
  addLesson,
  addLessonClean,
  editLesson,
  editLessonClean,
  getBlocks,
  addBlock,
  addBlockClean,
  editBlock,
  editBlockText,
  editBlockClean,
  removeBlock,
  removeBlockClean,
  listQuizz,
  addQuizz,
  addQuizzClean,
  getBlocksClean,
} from "../../store/actions"
import CoursesLesonnsFormWysiwyg from "./CoursesLessonsFormWysiwyg"
import QuizzFormModal from "./QuizzFormModal"

const CoursesLessonsForm = props => {
  const dispatch = useDispatch()

  const idLesson = props.lesson.idLesson
  const idCourse = props.course.idCurso

  const [insert, setInsert] = useState(props.insert)
  const [formError, setFormError] = useState(false)
  const [youtubeModal, setYoutubeModal] = useState(false)
  const [youtubeUrl, setYoutubeUrl] = useState("")
  const [blockToRemove, setBlockToRemove] = useState(undefined)
  const [showQuizzForm, setShowQuizzForm] = useState(undefined)

  useEffect(() => {
    if (!insert) {
      dispatch(getBlocks(idLesson))
      dispatch(listQuizz(idCourse))
    }
    return () => {
      setFormError(false)
      dispatch(addLessonClean())
      dispatch(getBlocksClean())
    }
  }, [insert, idLesson, idCourse])

  const {
    lessonAddLoading,
    lessonAddDone,
    lessonAddError,
    lessonEditDone,
    lessonEditError,
    blocks,
    blocksLoading,
    blockAddLoading,
    blockAddDone,
    blockAddError,
    blockEditDone,
    blockEditError,
    blockEditText,
    blockRemoveLoading,
    blockRemoveDone,
    blockRemoveError,
    quizz,
    quizzAddLoading,
    quizzAddDone,
    quizzAddError,
  } = useSelector(store => ({
    lessonAddLoading: store.LessonAdd.loading,
    lessonAddDone: store.LessonAdd.done,
    lessonAddError: store.LessonAdd.error,
    lessonEditDone: store.LessonEdit.done,
    lessonEditError: store.LessonEdit.error,
    blocks: store.BlocksList.blocks,
    blocksLoading: store.BlocksList.loading,
    blockAddLoading: store.BlockAdd.loading,
    blockAddDone: store.BlockAdd.done,
    blockAddError: store.BlockAdd.error,
    blockEditLoading: store.BlockEdit.loading,
    blockEditDone: store.BlockEdit.done,
    blockEditError: store.BlockEdit.error,
    blockRemoveLoading: store.BlockRemove.loading,
    blockRemoveDone: store.BlockRemove.done,
    blockRemoveError: store.BlockRemove.error,
    quizz: store.QuizzList.quizz,
    quizzAddLoading: store.QuizzAdd.loading,
    quizzAddDone: store.QuizzAdd.done,
    quizzAddError: store.QuizzAdd.error,
  }))

  /* useEffect(() => {
    const singleQuizz = quizz.find(element => element.question === "")
    if (singleQuizz !== undefined) setShowQuizzForm(singleQuizz)
    return () => {}
  }, [quizz]) */

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
    if (insert) {
      const request = {
        idCourse: props.course.idCurso,
        title: values.name,
        minutes: parseInt(values.minutes),
        order: props.lessonCount + 1,
      }

      dispatch(addLesson(request))
    } else {
      const request = {
        idLesson: props.lesson.idLesson,
        idCourse: props.course.idCurso,
        title: values.name,
        minutes: parseInt(values.minutes),
      }

      dispatch(editLesson(request))
    }
  }

  const handleRemoveBlock = block => {
    setBlockToRemove(block)
  }

  const handleConfirmRemoveBlock = id => {
    setBlockToRemove(undefined)
    dispatch(removeBlock(id))
  }

  const addText = () => {
    dispatch(
      addBlock({
        idLesson: props.lesson.idLesson,
        idCourse: props.course.idCurso,
        type: "text",
        value: "",
      })
    )
  }

  const handleAddQuizz = () => {
    const request = {
      idCourse: props.course.idCurso,
      question: "",
      idQuestionType: 1,
      requested: true,
      isPlatformValoration: true,
    }
    dispatch(addQuizz(request))
  }

  if (blockAddDone) {
    dispatch(addBlockClean())
    dispatch(getBlocks(props.lesson.idLesson))
  }

  if (quizzAddDone) {
    dispatch(addQuizzClean())
    dispatch(listQuizz(props.lesson.idLesson))
  }

  return (
    <Fragment>
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        {lessonAddError !== "" && (
          <div className="alert alert-danger">{lessonAddError}</div>
        )}
        {formError && (
          <div className="alert alert-danger">
            Por favor verifica los errores en el formulario.
          </div>
        )}
        <Row className="mb-3">
          <Col md={3} className="d-flex justify-content-end align-items-center">
            <Label for="name">* Nombre de la lección:</Label>
          </Col>
          <Col md={7}>
            <AvField
              name="name"
              value={props.lesson.title}
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3} className="d-flex justify-content-end align-items-center">
            <Label for="name">* Minutos aproximados:</Label>
          </Col>
          <Col md={1}>
            <AvField
              name="minutes"
              value={props.lesson.minutes}
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
                number: {
                  value: true,
                  errorMessage: "Este campo debe ser un número",
                },
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={6}>
            <Button className={`float-right  btn-block`} type="submit">
              <i className="fa fa-save"></i> Guardar
            </Button>
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={6}>
            <button
              onClick={props.onCancel}
              className="btn btn-light btn-block"
            >
              <i className="fa fa-undo"></i> Regresar
            </button>
          </Col>
        </Row>
      </AvForm>
      {!insert && (
        <Row className="mt-4">
          <Col xl={1} lg={2} md={2} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={4} xs={4} className="mb-3">
                <button
                  className={`${
                    blocks.filter(block => block.type === "text").length <= 0
                      ? ""
                      : "disabled"
                  } btn btn-block mb-3 btn-secondary`}
                  onClick={() => addText()}
                >
                  <i className="fa fa-font"></i>
                  <br />
                  Agregar texto
                </button>
              </Col>
              <Col xl={12} lg={12} md={12} sm={4} xs={4} className="mb-3">
                <button
                  className={`${
                    quizz.length <= 0 ? "" : "disabled"
                  } btn btn-block mb-3 btn-warning`}
                  onClick={() => (quizz.length <= 0 ? handleAddQuizz() : null)}
                >
                  <i className="fa fa-tasks"></i>
                  <br />
                  Agregar quizz
                </button>
              </Col>
            </Row>
          </Col>
          <Col xl={11} lg={10} md={10} sm={12} className="border">
            {blocks?.length < 1 && (
              <h4 className="text-center mt-4">-- Sin bloques --</h4>
            )}
            {blocks?.map(block => (
              <div key={`text-${block.idBlock}`} className="mt-4 mb-4">
                {block.type === "text" && (
                  <CoursesLesonnsFormWysiwyg block={block} />
                )}

                <div className="d-flex flex-row-reverse mt-2 pb-3">
                  <button
                    className="btn btn-dark"
                    onClick={() => handleRemoveBlock(block)}
                  >
                    <i className="fa fa-trash" /> Remover bloque
                  </button>
                </div>
                <hr />
              </div>
            ))}
          </Col>
        </Row>
      )}
      <Modal
        isOpen={blockToRemove !== undefined}
        backdrop={"static"}
        id="removeBlock"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="removeBlockLabel">
            Remover bloque
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setBlockToRemove(undefined)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p>¿Confirmas que deseas remover este bloque?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setBlockToRemove(undefined)
            }}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => handleConfirmRemoveBlock(blockToRemove.idBlock)}
          >
            Remover
          </button>
        </div>
      </Modal>
      <QuizzFormModal
        onClose={() => dispatch(listQuizz(props.lesson.idLesson))}
        quizz={showQuizzForm}
        course={props.course}
        reload={() => dispatch(listQuizz(idCourse))}
      />
      {lessonAddDone && insert && (
        <SweetAlert
          title="Lección registrada exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(addLessonClean())
            props.onCancel()
          }}
        >
          <p>La lección fue registrada de forma exitosa.</p>
        </SweetAlert>
      )}
      {lessonEditDone && !insert && (
        <SweetAlert
          title="Lección actualizada exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(editLessonClean())
          }}
        >
          <p>La lección fue actualizada de forma exitosa.</p>
        </SweetAlert>
      )}
      {blockRemoveDone && (
        <SweetAlert
          title="Bloque removido exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(removeBlockClean())
            dispatch(getBlocks(props.lesson.idLesson))
          }}
        >
          <p>El bloque fue removido de forma exitosa.</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

CoursesLessonsForm.propTypes = {
  insert: PropTypes.bool,
  lesson: PropTypes.object,
  lessonCount: PropTypes.number,
  course: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirmInsert: PropTypes.func,
}

export default CoursesLessonsForm
