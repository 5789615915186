import { Fragment, useState } from "react"
import { Table } from "reactstrap"
import PropTypes from "prop-types"
import moment from "moment"
import PdfViewerModal from "components/Common/PdfViewerModal"
import VideoPlayerModal from "components/Common/VideoPlayerModal"

const SupportMaterialsListTable = props => {
  const supportMaterials = props.supportMaterials
  const supportMaterialTypes = props.supportMaterialTypes

  const [pdf, setPdf] = useState(undefined)
  const [video, setVideo] = useState(undefined)

  const handleViewMaterial = material => {
    const extension = material.filePath.substr(
      material.filePath.lastIndexOf(".") + 1
    )
    if (/(pdf)$/gi.test(extension)) {
      setPdf(material)
    } else if (/(mp4)$/gi.test(extension)) {
      setVideo(material)
    } else {
      window.open(material.filePath)
    }
  }

  const getSupportMaterialCategory = id => {
    const supportMaterialType = supportMaterialTypes.find(
      supportMaterialType => {
        return supportMaterialType.idSupportMaterialType === id
      }
    )
    return supportMaterialType !== undefined
      ? supportMaterialType.SupportMaterialType
      : ""
  }

  return (
    <Fragment>
      {supportMaterials.length > 0 ? (
        <Fragment>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary btn-rounded"
              onClick={props.onAdd}
            >
              <i className="fa fa-plus"></i> Agregar material de apoyo
            </button>
          </div>
          <div className="">
            <Table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Fecha de registro</th>
                  <th colSpan={2} align="center" className="text-center">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {supportMaterials?.map((material, index) => {
                  return (
                    <tr key={material.idCourseSupportMaterial}>
                      <td>{index + 1}</td>
                      <td>{material.title}</td>
                      <td>
                        {getSupportMaterialCategory(
                          material.supportMaterialCategory
                        )}
                      </td>
                      <td className="text-center">
                        {moment(material.registrationDate).format(
                          "D [de] MMMM [de] YYYY [a las] HH:mm [hrs.]"
                        )}
                      </td>
                      <td>
                        <button
                          onClick={() => handleViewMaterial(material)}
                          className="btn btn-light"
                        >
                          <i className="fa fa-eye"></i> Ver
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => props.onDelete(material)}
                          className="btn btn-light"
                        >
                          <i className="fa fa-trash"></i> Remover
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          <PdfViewerModal
            show={pdf !== undefined}
            onCancel={() => setPdf(undefined)}
            title={pdf?.title}
            path={pdf?.filePath}
          />
          <VideoPlayerModal
            show={video !== undefined}
            onCancel={() => setVideo(undefined)}
            title={video?.title}
            path={video?.filePath}
          />
        </Fragment>
      ) : (
        <div className="text-center">
          No ha agregado material de apoyo.&nbsp;&nbsp;&nbsp;
          <button className="btn btn-primary btn-rounded" onClick={props.onAdd}>
            <i className="fa fa-plus"></i> Agregar material de apoyo
          </button>
        </div>
      )}
    </Fragment>
  )
}

SupportMaterialsListTable.propTypes = {
  supportMaterials: PropTypes.array,
  supportMaterialTypes: PropTypes.array,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
}

export default SupportMaterialsListTable
