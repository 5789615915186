import React, { useState } from "react"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import PropTypes from "prop-types"
import { Modal, Row, Col } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const PdfViewerModal = props => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)

  toastr.options = {
    positionClass: "toast-bottom-center",
    closeButton: true,
    debug: false,
    progressBar: false,
    preventDuplicates: true,
    newestOnTop: true,
  }

  return (
    <Modal
      isOpen={props.show}
      backdrop={"static"}
      size={"lg"}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {props.title}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onCancel()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <Document
          className="w-100"
          file={props.path}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page className={`w-100`} pageNumber={pageNumber} />
        </Document>
        <Row className="pt-3">
          <Col>
            <p>
              Página {pageNumber} de {numPages}
            </p>
          </Col>
          <Col className="d-flex justify-content-end text-right">
            <button
              onClick={() => setPageNumber(pageNumber - 1)}
              className={`btn btn-light ${pageNumber == 1 ? "disabled" : ""}`}
              type="button"
            >
              <i className="fa fa-chevron-left"></i>
            </button>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <button
              onClick={() => setPageNumber(pageNumber + 1)}
              className={`btn btn-light ${
                numPages == pageNumber ? "disabled" : ""
              }`}
              type="button"
            >
              <i className="fa fa-chevron-right"></i>
            </button>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <button
              className={`btn btn-light`}
              onClick={() => {
                toastr.success(
                  "La ruta del archivo fue copiada al portapapeles",
                  "Ruta copiada"
                )
                navigator.clipboard.writeText(props.path)
              }}
              type="button"
            >
              <i className="fa fa-clipboard"></i>
            </button>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <button
              className={`btn btn-light`}
              onClick={() => {
                toastr.success(
                  "La ruta del archivo fue copiada al portapapeles",
                  "Ruta copiada"
                )
                navigator.clipboard.writeText(props.path)
              }}
              type="button"
            >
              <i className="fa fa-code"></i>
            </button>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

PdfViewerModal.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  show: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default PdfViewerModal
