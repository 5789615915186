import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody, CardTitle, Modal, Input } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import CourseExamForm from "./CourseExamForm"
import CourseExamQuestions from "./CourseExamQuestions"
import TrueFalseQuestionModal from "./TrueFalseQuestionModal"
import MultiQuestionModal from "./MultiQuestionModal"
import OrderQuestionModal from "./OrderQuestionModal"
import {
  getExam,
  getExamClean,
  addExam,
  addExamClean,
  editExam,
  editExamClean,
  listQuestions,
  removeQuestion,
  removeQuestionClean,
  getLessons,
  addQuestion,
  getQuestion,
  addQuestionClean,
  getQuestionClean,
  listQuestionsClean,
} from "store/actions"

const questionTypes = {
  TF: { id: 2, name: "true/false" },
  MULTI: { id: 1, name: "multiple" },
  ORDER: { id: 3, name: "order" },
}

const CourseExam = props => {
  const course = props.course
  const idCurso = props.course.idCurso

  const [showQuestionForm, setShowQuestionForm] = useState(undefined)
  const [questionType, setQuestionType] = useState(0)
  const [questionToEdit, setQuestionToEdit] = useState(undefined)

  const dispatch = useDispatch()

  const {
    lessons,
    exam,
    loading,
    error,
    done,
    addLoading,
    addError,
    addDone,
    editLoading,
    editError,
    editDone,
    questions,
    loadingQuestions,
    errorQuestions,
    questionAddLoading,
    questionAddDone,
    questionAddError,
    questionAdd,
    questionGetLoading,
    questionGetDone,
    questionGetError,
    questionGet,
    removeQuestionLoading,
    removeQuestionError,
    removeQuestionDone,
  } = useSelector(store => ({
    lessons: store.LessonsList.lessons,
    exam: store.ExamGet.exam,
    loading: store.ExamGet.loading,
    error: store.ExamGet.error,
    done: store.ExamGet.done,
    addLoading: store.ExamAdd.loading,
    addError: store.ExamAdd.error,
    addDone: store.ExamAdd.done,
    editLoading: store.ExamEdit.loading,
    editError: store.ExamEdit.error,
    editDone: store.ExamEdit.done,
    questions: store.QuestionsList.questions,
    loadingQuestions: store.QuestionsList.loading,
    errorQuestions: store.QuestionsList.error,
    questionAddLoading: store.QuestionAdd.loading,
    questionAddDone: store.QuestionAdd.done,
    questionAddError: store.QuestionAdd.error,
    questionAdd: store.QuestionAdd.question,
    questionGetLoading: store.QuestionGet.loading,
    questionGetDone: store.QuestionGet.done,
    questionGetError: store.QuestionGet.error,
    questionGet: store.QuestionGet.question,
    removeQuestionLoading: store.QuestionRemove.loading,
    removeQuestionError: store.QuestionRemove.error,
    removeQuestionDone: store.QuestionRemove.done,
  }))

  const idExam = exam?.idCourseExam

  useEffect(() => {
    dispatch(getExam(idCurso))
    dispatch(getLessons(idCurso))
    return () => {
      dispatch(getExamClean())
    }
  }, [idCurso])

  useEffect(() => {
    if (idExam !== undefined) dispatch(listQuestions(idExam))
    return () => {
      dispatch(listQuestionsClean())
    }
  }, [idExam])

  const getQuestionTypeEnum = idQuestionType => {
    return idQuestionType == 1
      ? questionTypes.MULTI
      : idQuestionType == 2
      ? questionTypes.TF
      : questionTypes.ORDER
  }

  const handleUpdate = request => {
    dispatch(editExam(request))
  }

  const handleEditQuestion = question => {
    setQuestionToEdit(question)
    setShowQuestionForm(
      getQuestionTypeEnum(question.questionType.idQuestionType)
    )
  }

  const handleDeleteQuestion = question => {
    dispatch(removeQuestion(question.idQuestion))
  }

  const handleAddQuestion = questionType => {
    setQuestionType(questionType)
    const request = {
      courseExam: idExam,
      idQuestionType: questionType.id,
      idLessonRef: null,
      question: "",
      image: "",
    }

    dispatch(addQuestion(request))
  }

  if (done && (exam === null || exam === undefined)) {
    dispatch(getExamClean())
    dispatch(
      addExam({
        idCourse: idCurso,
        minApprove: 1,
        questionCount: 1,
        Aleatory: true,
        examTimeOut: 1,
        endExamOnTimeOut: true,
      })
    )
  }

  if (addDone) {
    dispatch(addExamClean())
    dispatch(getExam(idCurso))
  }

  if (editDone) {
    dispatch(editExamClean())
    dispatch(getExam(idCurso))
  }

  if (questionAddDone) {
    const idAddQuestion = questionAdd.id
    dispatch(addQuestionClean())
    dispatch(getQuestion(idAddQuestion))
  }

  if (questionGetDone) {
    dispatch(getQuestionClean())
    setQuestionToEdit(questionGet)
    const type = getQuestionTypeEnum(questionGet.questionType.idQuestionType)
    setShowQuestionForm(type)
  }

  if (removeQuestionDone) {
    dispatch(removeQuestionClean())
    dispatch(listQuestions(idExam))
  }

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <CardTitle className="mb-3">Examen</CardTitle>
            <CourseExamForm
              exam={exam}
              course={props.course}
              onUpdate={request => handleUpdate(request)}
            />
            <hr />
            <Row>
              <Col xl={2} lg={2} md={3}>
                <Row>
                  <button
                    onClick={() => {
                      handleAddQuestion(questionTypes.TF)
                    }}
                    className={`btn btn-primary mb-2 ${
                      exam === null || exam === undefined ? "disabled" : ""
                    }`}
                  >
                    <i className="fa fa-question-circle"></i>
                    <br />
                    Falso/Verdadero
                  </button>
                  <button
                    onClick={() => {
                      handleAddQuestion(questionTypes.MULTI)
                    }}
                    className={`btn btn-primary mb-2 ${
                      exam === null || exam === undefined ? "disabled" : ""
                    }`}
                  >
                    <i className="fa fa-question-circle"></i>
                    <br />
                    Opción múltiple
                  </button>
                  <button
                    onClick={() => {
                      handleAddQuestion(questionTypes.ORDER)
                    }}
                    className={`btn btn-primary mb-2 ${
                      exam === null || exam === undefined ? "disabled" : ""
                    }`}
                  >
                    <i className="fa fa-question-circle"></i>
                    <br />
                    Orden
                  </button>
                </Row>
              </Col>
              <Col xl={1} lg={1} md={1}></Col>
              <Col xl={9} lg={9} md={8}>
                <CourseExamQuestions
                  exam={exam}
                  course={course}
                  questions={questions ?? []}
                  lessons={lessons}
                  onDelete={question => handleDeleteQuestion(question)}
                  onEdit={question => handleEditQuestion(question)}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <TrueFalseQuestionModal
        show={showQuestionForm === questionTypes.TF}
        question={questionToEdit}
        exam={exam}
        course={props.course}
        onCancel={() => setShowQuestionForm(undefined)}
        onConfirm={() => {
          dispatch(listQuestions(idExam))
          setShowQuestionForm(undefined)
        }}
      />
      <MultiQuestionModal
        show={showQuestionForm === questionTypes.MULTI}
        question={questionToEdit}
        exam={exam}
        course={props.course}
        onCancel={() => setShowQuestionForm(undefined)}
        onConfirm={() => {
          dispatch(listQuestions(idExam))
          setShowQuestionForm(undefined)
        }}
      />
      <OrderQuestionModal
        show={showQuestionForm === questionTypes.ORDER}
        question={questionToEdit}
        exam={exam}
        course={props.course}
        onCancel={() => setShowQuestionForm(undefined)}
        onConfirm={() => {
          dispatch(listQuestions(idExam))
          setShowQuestionForm(undefined)
        }}
      />
    </Row>
  )
}

CourseExam.propTypes = {
  course: PropTypes.object,
}

export default CourseExam
