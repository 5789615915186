import { del, get, post, postFile } from "./api_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const token = localStorage.getItem("token")
  if (token) return token
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Auth Methods
const postJwtLogin = data => post("login/authenticate", data)
const lostPassword = data => post("login/LostPassword", data)
const recoverPassword = data => post("login/RecoverPassword", data)

// Users Methods
const whoIAm = () => get("Users/WhoAmI")
const getUsers = () => get("Users")
const getUsersByRole = idRol => get(`Users/GetByProfile?idRol=${idRol}`)
const getUser = id => get(`Users/${id}`)
const addUser = data => post("Users/Add", data)
const updateUser = data => post("Users/Update", data)
const changeUserPassword = data => post("Users/ChangePassword", data)
const activateDeactivateUser = data => post("Users/activateDeactivate", data)
const postAcceptPrivacyTyc = data => post("Users/AceptTYCAndPrivacity", data)

// Profiles Methods
const getProfiles = () => get("Profiles")
const getProfile = id => get(`Profiles/${id}`)
const addProfile = data => post("Profiles/Add", data)
const updateProfile = data => post("Profiles/Update", data)

// Groups Methods
const getGroups = () => get("Groups")
const getGroup = id => get(`Groups/${id}`)
const addGroup = data => post("Groups/Add", data)
const updateGroup = data => post("Groups/Update", data)

// Positions Methods
const getPositions = () => get("Positions")
const getPosition = id => get(`Positions/${id}`)
const addPosition = data => post("Positions/Add", data)
const updatePosition = data => post("Positions/Update", data)

// Clients
const getClients = () => get("Clients")
const getClient = id => get(`Clients/${id}`)
const addClient = data => post("Clients/Add", data)
const updateClient = data => post("Clients/Update", data)

// Businesses
const getBusinesses = () => get("Business")
const getBusiness = id => get(`Business/${id}`)
const addBusiness = data => post("Business/Add", data)
const updateBusiness = data => post("Business/Update", data)

// Commercial areas
const getShoppingAreas = () => get("ZonaComercial")
const getShoppingArea = id => get(`ZonaComercial/${id}`)
const addShoppingArea = data => post("ZonaComercial/Add", data)
const updateShoppingArea = data => post("ZonaComercial/Update", data)

// Knowledge categories
const getKnowledgeCategories = () => get("KnowledgeCategory")
const getKnowledgeCategory = id => get(`KnowledgeCategory/${id}`)
const addKnowledgeCategory = data => post("KnowledgeCategories/Add", data)
const updateKnowledgeCategory = data => post("KnowledgeCategories/Update", data)

// Skills
const getSkills = () => get("Abilities")
const getSkill = id => get(`Abilities/${id}`)
const addSkill = data => post("Abilites/Add", data)
const updateSkill = data => post("Abilites/Update", data)

// Levels
const getLevels = () => get("Levels")
const getLevel = id => get(`Levels/${id}`)
const addLevel = data => post("Levels/Add", data)
const updateLevel = data => post("Levels/Update", data)

// Course knowledges
const getCourseKnowledges = () => get("CourseKnowledge")
const getCourseKnowledge = id => get(`CourseKnowledge/${id}`)
const addCourseKnowledge = data => post("CourseKnowledge/Add", data)
const updateCourseKnowledge = data => post("CourseKnowledge/Update", data)

// Support material types
const getSupportMaterialTypes = () => get("SuportMaterialType/Get")
const getSupportMaterialType = id => get(`SuportMaterialType/Get/${id}`)
const insertSupportMaterialType = data => post("SuportMaterialType/Add", data)
const updateSupportMaterialType = data =>
  post("SuportMaterialType/Update", data)

// Courses
const getCourses = () => get("Courses")
const addCourse = data => post("Courses/Add", data)
const getCourse = id => get(`Courses/${id}`)
const updateCourse = data => post(`Courses/Update`, data)
const updateCourseStatus = (idCourse, status) =>
  get(`Courses/UpdateStatus?idCourse=${idCourse}&status=${status}`)
const uploadImage = data => post("Courses/UploadImage", data)
const getCoursesByUser = idUser =>
  get(`Courses/GetCoursesByUser?idUser=${idUser}`)

// Lessons
const getLessons = idCourse => get(`Courses/Lessons?idCourse=${idCourse}`)
const getOneLesson = idLesson => get(`Courses/Lessons?idLesson=${idLesson}`)
const addLesson = data => post(`Courses/Lessons/Add`, data)
const updateLesson = data => post(`Courses/Lessons/Update`, data)
const deleteLesson = id => post(`Courses/Lessons/Delete?idLesson=${id}`)
const updateLessonPositions = data =>
  post(`Courses/Lessons/UpdatePositions`, data)
const startLesson = payload => post(`Courses/Lessons/Start`, payload)
const finishLesson = payload => post(`Courses/Lessons/Finish`, payload)

// Lesson blocks
const getBlocks = idLesson => get(`Courses/Lessons/Blocks?idLesson=${idLesson}`)
const getBlock = idBlock => get(`Courses/Lessons/Blocks?idBlock=${idBlock}`)
const addBlock = data => post(`Courses/Lessons/Blocks/Add`, data)
const updateBlock = data => post(`Courses/Lessons/Blocks/Update`, data)
const deleteBlock = id => post(`Courses/Lessons/Blocks/Delete?idBlock=${id}`)

// Support materials
const getSupportMaterials = idCourse =>
  get(`Courses/GetSupportMaterials?idCourse=${idCourse}`)
const deleteSupportMaterial = id =>
  post(`Courses/DeleteSupportMaterial?idSupportMaterial=${id}`)
const addSupportMaterial = payload => {
  const form = payload.form
  const idCurso = payload.request.idCourse
  const title = payload.request.title
  const idSupportMaterialType = payload.request.idSupportMaterialType
  return postFile(
    `Courses/UploadSupportFile?idCourse=${idCurso}&title=${title}&idSupportMaterialType=${idSupportMaterialType}`,
    form
  )
}

// Quizzes
const getQuizzes = idCourse => get(`Quizzes/GetByCourse?idCourse=${idCourse}`)
const getQuizz = idQuizz => get(`Quizzes/${idQuizz}`)
const addQuizz = data => post(`Quizzes/Add`, data)
const updateQuizz = data => post(`Quizzes/Update`, data)
const getQuizzAnswers = idQuizz => get(`Quizzes/Answers/Get?id=${idQuizz}`)
const addQuizzAnswer = data => post(`Quizzes/Answers/Add`, data)
const updateQuizzAnswer = data => post(`Quizzes/Answers/Update`, data)
const deleteQuizzAnswer = id => post(`Quizzes/Answers/Delete`, id)

// Exam
const getExam = idCourse => post(`Courses/Exams/ByCourse?idCourse=${idCourse}`)
const addExam = form => post(`Courses/Exams/Add`, form)
const updateExam = form => post(`Courses/Exams/Update`, form)

// Question
const getQuestions = idExam =>
  get(`Courses/Exams/Questions/All?idExam=${idExam}`)
const getQuestion = idQuestion =>
  get(`Courses/Exams/Questions/Single?idQuestion=${idQuestion}`)
const addQuestion = form => post(`Courses/Exams/Questions/Add`, form)
const updateQuestion = form => post(`Courses/Exams/Questions/Update`, form)
const deleteQuestion = id => post(`Courses/Exams/Questions/Delete`, { id: id })

// Answers
const getAnswers = idQuestion =>
  get(`Courses/Exams/Questions/Answers/All?idQuestion=${idQuestion}`)
const getAnswer = idAnswer =>
  get(`Courses/Exams/Questions/Answers/Single?idAnswer=${idAnswer}`)
const addAnswer = form => post(`Courses/Exams/Questions/Answers/Add`, form)
const addAnswersMulti = form =>
  post(`Courses/Exams/Questions/Answers/AddMultiple`, form)
const updateAnswer = form =>
  post(`Courses/Exams/Questions/Answers/Update`, form)
const deleteAnswer = id =>
  post(`Courses/Exams/Questions/Answers/Delete`, { id: id })

// Groups participants
const getGroupParticipants = idGroup =>
  get(`Groups/GetGroupParticipants?idGroup=${idGroup}`)
const getGroupCourses = payload => {
  const group = payload.idGroup ?? 0
  const course = payload.idCourse ?? 0
  return get(`Groups/GetGroupCourses?idGroup=${group}&idCourse=${course}`)
}
const insertGroupParticipant = data => post(`Groups/AddParticipant`, data)
const deleteGroupParticipant = data => post(`Groups/RemoveParticipant`, data)
const insertGroupCourse = data => post(`Groups/AddCourse`, data)
const deleteGroupCourse = data => post(`Groups/RemoveCourse`, data)

// Polls
const getPolls = idCourse => get(`Poll/GetByCourse?idCourse=${idCourse}`)
const insertPoll = data => post(`Poll/Add`, data)
const updatePoll = data => post(`Poll/Update`, data)
const getPollAnswers = idPoll =>
  get(`Poll/Answers/GetByCourse?idCourse=${idPoll}`)
const insertPollAnswer = data => post(`Poll/Answers/Add`, data)
const updatePollAnswer = data => post(`Poll/Answers/Update`, data)
const deletePollAnswer = data => post(`Poll/Answers/Delete`, data)

// Favourites
const addFav = idCourse => post(`Courses/Favourite/Add?idCourse=${idCourse}`)
const removeFav = idCourse =>
  post(`Courses/Favourite/Remove?idCourse=${idCourse}`)

// Notes
const insertNote = data => post(`Courses/Lessons/Notes/Add`, data)
const deleteNote = idNote => post(`Courses/Lessons/Notes/Remove`, idNote)
const getNotes = idCourse => get(`Courses/Lessons/Notes?idCourse=${idCourse}`)

// Images
const addImage = file => postFile("Courses/UploadImage", file)

// Charts
const yearData = [
  {
    name: "Completado",
    data: [67, 55, 41, 67, 22, 97, 36],
  },
  {
    name: "En proceso",
    data: [13, 23, 20, 8, 57, 3, 31],
  },
  {
    name: "Sin iniciar",
    data: [20, 22, 39, 25, 21, 0, 33],
  },
]

const monthData = [
  {
    name: "Completado",
    data: [90, 100, 85, 55, 41, 80, 21],
  },
  {
    name: "En proceso",
    data: [1, 0, 13, 29, 47, 5, 30],
  },
  {
    name: "Sin iniciar",
    data: [9, 0, 3, 24, 12, 15, 49],
  },
]

const weekData = [
  {
    name: "Completado",
    data: [74, 52, 61, 47, 72, 53, 88],
  },
  {
    name: "En proceso",
    data: [13, 23, 20, 38, 13, 27, 9],
  },
  {
    name: "Sin iniciar",
    data: [13, 25, 19, 15, 15, 20, 3],
  },
]

const getWeeklyData = () => weekData
const getYearlyData = () => yearData
const getMonthlyData = () => monthData

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin,
  lostPassword,
  recoverPassword,
  getUsers,
  getUsersByRole,
  getUser,
  addUser,
  updateUser,
  changeUserPassword,
  activateDeactivateUser,
  postAcceptPrivacyTyc,
  getProfiles,
  getProfile,
  addProfile,
  updateProfile,
  getGroups,
  getGroup,
  addGroup,
  updateGroup,
  getPositions,
  getPosition,
  addPosition,
  updatePosition,
  getClients,
  getClient,
  addClient,
  updateClient,
  whoIAm,
  getWeeklyData,
  getYearlyData,
  getMonthlyData,
  getBusinesses,
  getBusiness,
  addBusiness,
  updateBusiness,
  getShoppingAreas,
  getShoppingArea,
  addShoppingArea,
  updateShoppingArea,
  getKnowledgeCategories,
  getKnowledgeCategory,
  addKnowledgeCategory,
  updateKnowledgeCategory,
  getLevels,
  getLevel,
  addLevel,
  updateLevel,
  getSkills,
  getSkill,
  addSkill,
  updateSkill,
  getSupportMaterialTypes,
  getSupportMaterialType,
  insertSupportMaterialType,
  updateSupportMaterialType,
  getCourseKnowledges,
  getCourseKnowledge,
  addCourseKnowledge,
  updateCourseKnowledge,
  getCourses,
  getCoursesByUser,
  addCourse,
  updateCourse,
  updateCourseStatus,
  getCourse,
  uploadImage,
  getLessons,
  getOneLesson,
  addLesson,
  updateLesson,
  deleteLesson,
  updateLessonPositions,
  getBlocks,
  getBlock,
  addBlock,
  updateBlock,
  deleteBlock,
  addImage,
  getGroupParticipants,
  getGroupCourses,
  insertGroupParticipant,
  deleteGroupParticipant,
  insertGroupCourse,
  deleteGroupCourse,
  getSupportMaterials,
  addSupportMaterial,
  deleteSupportMaterial,
  getQuizzes,
  getQuizz,
  addQuizz,
  updateQuizz,
  getQuizzAnswers,
  addQuizzAnswer,
  updateQuizzAnswer,
  deleteQuizzAnswer,
  getExam,
  addExam,
  updateExam,
  getQuestions,
  getQuestion,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  getAnswers,
  getAnswer,
  addAnswer,
  addAnswersMulti,
  updateAnswer,
  deleteAnswer,
  getPolls,
  insertPoll,
  updatePoll,
  getPollAnswers,
  insertPollAnswer,
  updatePollAnswer,
  deletePollAnswer,
  addFav,
  removeFav,
  insertNote,
  deleteNote,
  getNotes,
  startLesson,
  finishLesson,
}
