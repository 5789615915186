import { Fragment, useState } from "react"
import { PropTypes } from "prop-types"
import {
  Col,
  Row,
  Media,
  Input,
  Modal,
  Table,
  Card,
  CardBody,
} from "reactstrap"

const CourseLessonIntro = props => {
  const [modal, setModal] = useState(true)

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <h3>Acerca de este curso</h3>
        </Col>
        <Col md={6}>
          <Card>
            <CardBody>
              <Table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Categoría</td>
                    <td>
                      {props.knowledgeCategories === undefined
                        ? ""
                        : props.knowledgeCategories
                            .map(category => category.label.trim())
                            .join(", ")}
                    </td>
                  </tr>
                  <tr>
                    <td>Clave curso</td>
                    <td>{props.course?.claveCurso}</td>
                  </tr>
                  <tr>
                    <td>Curso previo</td>
                    <td>
                      {props.previousCourses === undefined
                        ? ""
                        : props.previousCourses.length <= 0
                        ? "Ninguno"
                        : props.previousCourses
                            .map(category => category.label.trim())
                            .join(", ")}
                    </td>
                  </tr>
                  <tr>
                    <td>Responsable</td>
                    <td>
                      {props.responsibles === undefined
                        ? ""
                        : props.responsibles
                            .map(category => category.label.trim())
                            .join(", ")}
                    </td>
                  </tr>
                  <tr>
                    <td>Nivel</td>
                    <td>{props.level}</td>
                  </tr>
                  <tr>
                    <td>Dedicación</td>
                    <td>{props.course?.tiempoDedicacion} minutos</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <CardBody>
              <Table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Mínimo aprobatorio:</td>
                  </tr>
                  <tr>
                    <td>{props.course?.minimoAprovatorio} %</td>
                  </tr>
                  <tr>
                    <td>Calificación usuarios:</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text-center">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="far fa-star" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

CourseLessonIntro.propTypes = {
  knowledgeCategories: PropTypes.array,
  course: PropTypes.object,
  previousCourses: PropTypes.array,
  responsibles: PropTypes.array,
  level: PropTypes.string,
}

export default CourseLessonIntro
