import { Fragment, useEffect, useState } from "react"
import { Col, Row, Label, Button, Input, Modal } from "reactstrap"
import PropTypes from "prop-types"
import { AvForm, AvField } from "availity-reactstrap-validation"

const CourseExamForm = props => {
  const exam = props.exam
  const [formError, setFormError] = useState(false)

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    const request = {
      courseExam: exam.idCourseExam,
      idCourse: props.course.idCurso,
      minApprove: props.course.minimoAprovatorio,
      questionCount: parseInt(values.questionNumber),
      Aleatory: values.random == "true",
      examTimeOut: parseInt(values.duration),
      endExamOnTimeOut: values.extraTime,
    }

    props.onUpdate(request)
  }

  return (
    <Fragment>
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={4} className="d-flex justify-content-end align-items-center">
            <Label for="random">* Preguntas aleatorias:</Label>
          </Col>
          <Col md={3}>
            <AvField
              name="random"
              className="form-control"
              value={exam ? exam.Aleatory : true}
              placeholder=""
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </AvField>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4} className="d-flex justify-content-end align-items-center">
            <Label for="questionNumber">* Preguntas a presentar:</Label>
          </Col>
          <Col md={2}>
            <AvField
              name="questionNumber"
              className="form-control"
              value={exam ? exam.questionsCount : 1}
              placeholder=""
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              {[...Array(20)].map((e, i) => (
                <option key={`questions-${i}`} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </AvField>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4} className="d-flex justify-content-end align-items-center">
            <Label for="duration">* Duración del examen:</Label>
          </Col>
          <Col md={2}>
            <AvField
              name="duration"
              className="form-control"
              placeholder=""
              value={exam ? exam.examTomeOut : 0}
              type="number"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
                number: {
                  value: true,
                  errorMessage: "Este campo debe contener únicamente números",
                },
              }}
            />
          </Col>
          <Col md={6}>
            <p className="form-control-plaintext">Minutos</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4} className="d-flex justify-content-end align-items-center">
            <Label for="examTime">
              * Finalizar el examen al terminar el tiempo:
            </Label>
          </Col>
          <Col md={2}>
            <AvField
              name="extraTime"
              className="form-control"
              value={exam ? exam.endExamOnTimeOut : true}
              placeholder=""
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </AvField>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={{ size: 8, offset: 4 }}>
            <Button className={`float-right`} type="submit">
              <i className="fa fa-save"></i> Guardar
            </Button>
          </Col>
        </Row>
      </AvForm>
    </Fragment>
  )
}

CourseExamForm.propTypes = {
  exam: PropTypes.object,
  course: PropTypes.object,
  onUpdate: PropTypes.func,
}

export default CourseExamForm
