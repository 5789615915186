import { withRouter } from "react-router"
import { useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import GroupsForm from "components/Groups/GroupsForm"
import UsersGroupListTable from "components/Groups/UsersGroupListTable"
import CoursesGroupListTable from "components/Groups/CoursesGroupListTable"
import NotificationsGroup from "components/Groups/NotificationsGroup"
import ForumGroup from "components/Groups/ForumGroup"
import ProgressGroup from "components/Groups/ProgressGroup"
import CoursesForm from "components/Courses/CoursesForm"
import CoursesLessons from "components/Courses/CoursesLessons"
import CoursesLessonsDetails from "components/Courses/CoursesLessonsDetail"
import {
  getCourse,
  getCourses,
  getKnowledgeCategories,
  getSkills,
  getLevels,
  getCourseKnowledges,
  getUsers,
  getLessons,
} from "store/actions"
import CourseLessonIntro from "components/Courses/CourseLessonIntro"

const CourseInfo = props => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState("5")
  const [categoriesSelectedMulti, setCategoriesSelectedMulti] = useState([])
  const [previousCoursesSelectedMulti, setPreviousCoursesSelectedMulti] =
    useState([])
  const [responsiblesSelectedMulti, setResponsiblesSelectedMulti] = useState([])
  const [habilitiesSelectedMulti, setHabilitiesSelectedMulti] = useState([])
  const [knowledgesSelectedMulti, setKnowledgesSelectedMulti] = useState([])
  const [suggestedCoursesSelectedMulti, setSuggestedCoursesSelectedMulti] =
    useState([])

  const {
    course,
    knowledgeCategories,
    skills,
    levels,
    users,
    courseKnowledges,
    lessons,
  } = useSelector(store => ({
    course: store.CourseGet.course,
    knowledgeCategories: store.KnowledgeCategoriesList.knowledgeCategories,
    skills: store.SkillsList.skills,
    levels: store.LevelsList.levels,
    courseKnowledges: store.CourseKnowledgesList.courseKnowledges,
    users: store.UsersList.users,
    lessons: store.LessonsList.lessons,
  }))

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getCourse(id))
      dispatch(getLessons(id))
    }
    dispatch(getUsers())
    dispatch(getCourses())
    dispatch(getKnowledgeCategories())
    dispatch(getSkills())
    dispatch(getLevels())
    dispatch(getCourseKnowledges())
  }, [id])

  const knowCat = course?.knowledgeCategories
  const prevCourses = course?.PreviousCourses
  const sugCourses = course?.SuggestedCourses
  const respCourses = course?.ResponsiblesCourses
  const know = course?.knowledges
  const skl = course?.abilities
  const vc = course?.valoracionCurso

  useEffect(() => {
    let prevCoursesArray = []
    let sugCoursesArray = []
    let knowCatArray = []
    let knowArray = []
    let sklArray = []
    let rArray = []
    if (respCourses !== undefined)
      respCourses.forEach(respCourse => {
        //const user = users.find(user => user.idUsuario === respCourse.idUser)
        //if (user !== undefined)
        rArray.push({
          label: ``,
          value: respCourses.idUser,
        })
      })

    if (prevCourses !== undefined)
      prevCourses.forEach(prevCourse => {
        prevCoursesArray.push({
          label: `${prevCourse.courseName}`,
          value: prevCourse.idPreviousCourse,
        })
      })

    if (sugCourses !== undefined)
      sugCourses.forEach(sugCourse => {
        sugCoursesArray.push({
          label: `${sugCourse.courseName}`,
          value: sugCourse.idSuggedtedCourse,
        })
      })

    if (knowCat !== undefined)
      knowCat.forEach(knowC => {
        knowCatArray.push({
          label: `${knowC.knowledgeCategory}`,
          value: knowC.idKnowledgeCategory,
        })
      })

    if (know !== undefined)
      know.forEach(knowledge => {
        knowArray.push({
          label: `${knowledge.knowledge}`,
          value: knowledge.idKnowledge,
        })
      })

    if (skl !== undefined)
      skl.forEach(skill => {
        sklArray.push({
          label: `${skill.ability}`,
          value: skill.idAbility,
        })
      })

    setPreviousCoursesSelectedMulti(prevCoursesArray)
    setSuggestedCoursesSelectedMulti(sugCoursesArray)
    setCategoriesSelectedMulti(knowCatArray)
    setKnowledgesSelectedMulti(knowArray)
    setHabilitiesSelectedMulti(sklArray)
    setResponsiblesSelectedMulti(rArray)
  }, [prevCourses, sugCourses, knowCat, vc, users])

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return course !== undefined ? (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{`Curso: ${course.nombreCurso}`} | CELF</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h5>Curso</h5>
              <h2 className="main-celf-color">{course.nombreCurso}</h2>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  {
                    key: "dashboard",
                    title: "Dashboard",
                    link: "/dashboard",
                  },
                  { key: "courses", title: "Cursos", link: "/my-courses" },
                  {
                    key: "edit_group",
                    title: course.nombreCurso,
                  },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleIconCustom("5")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-image"></i> Portada
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-image"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleIconCustom("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-list"></i> Información
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleIconCustom("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-book-open"></i> Contenido
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-book-open"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "6",
                        })}
                        onClick={() => {
                          toggleIconCustom("6")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-medal"></i> Calificación
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-medal"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleIconCustom("3")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-sticky-note"></i> Notas
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-sticky-note"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleIconCustom("4")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-question-circle"></i> Ayuda
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={activeTab}
                    className="p-3 text-muted mt-4"
                  >
                    <TabPane tabId="1">
                      <CourseLessonIntro
                        course={course}
                        knowledgeCategories={categoriesSelectedMulti}
                        previousCourses={previousCoursesSelectedMulti}
                        responsibles={responsiblesSelectedMulti}
                        level={
                          levels.find(
                            level => level.idLevel === course.nivelCurso
                          )?.level
                        }
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <CoursesLessonsDetails
                        course={course}
                        lessons={lessons}
                      />
                    </TabPane>
                    <TabPane tabId="3"></TabPane>
                    <TabPane tabId="4"></TabPane>
                    <TabPane tabId="5">
                      <Row>
                        <Col md={12}>
                          <img src={course.coverImage} className="w-100" />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6"></TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <div></div>
  )
}

export default withRouter(CourseInfo)
